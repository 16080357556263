import React from 'react'

import { Link } from 'react-router-dom'
import { getFeatures, getRole } from '../services/auth'

export default function CaixaLinks() {
  return (
    getFeatures().includes('Stock') && (
      <div className="buttons-container">
        <p>
          <Link to="/caixa" className="">
            <button className="button btn btn-danger">
              <span className="button-font-size">Caixa</span>
            </button>
          </Link>
        </p>
        {!getFeatures().includes('PostPaid') && (
          <p>
            <Link to="/entrada" className="">
              <button className="button btn btn-primary">
                <span className="button-font-size">Entrada</span>
              </button>
            </Link>
          </p>
        )}
        {getFeatures().includes('CreditBetweenTags') && getRole().includes('Payment') && (
          <p>
            <Link to="/trasferirCredito" className="">
              <button className="button btn btn-dark">
                <span className="button-font-size">Transferir Crédito entre Comandas</span>
              </button>
            </Link>
          </p>
        )}

        <hr />
      </div>
    )
  )
}
