import React from 'react'
import PropTypes from 'prop-types'
import { getEntry, getExit, totalCard, totalCash } from '../helpers/Numbers'

CaixaRelatorioBasico.propTypes = {
  transactions: PropTypes.array,
  initialCashAmount: PropTypes.number
}

export default function CaixaRelatorioBasico({ transactions, initialCashAmount }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col">Dinheiro em Caixa </div>
        <div className="col">R$: {initialCashAmount + totalCash(transactions)},00</div>
      </div>
      <div className="row">
        <div className="col">Valor em Cartão</div>
        <div className="col">
          R$: {totalCard(transactions)}
          ,00
        </div>
      </div>
      <div className="row">
        <div className="col">Valor em Dinheiro</div>
        <div className="col">
          R$: {totalCash(transactions)}
          ,00
        </div>
      </div>
      <div className="row">
        <div className="col">Total</div>
        <div className="col">
          R$: {initialCashAmount + totalCash(transactions) + totalCard(transactions)}
          ,00
        </div>
      </div>
    </div>
  )
}
