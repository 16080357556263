import React, { useState, useEffect } from 'react'

import api from '../services/api'
import filterIt from '../views/bar'

import { getClientId } from '../services/auth'
import { getPromotions } from '../services/promotions'

export default function Promocoes() {
  const [produtos, setProdutos] = useState([])
  const [promo, setPromo] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    onLoad()
  }, [])

  useEffect(() => {
    if(search.length > 3) {
      handleSubmit()
    }
  }, [search])

  async function onLoad() {
    try {
      const promos = await getPromotions()
      setPromo(promos)
    } catch (e) {
      console.log(e.response)
    }
  }

  async function handleSubmit() {
    const d = await api.get('/product/search/' + search.toUpperCase())
    setProdutos(d.data)
  }

  async function apagar(id) {
    const bora = window.confirm('Tem certeza que deseja apagar?')
    if (bora) {
      try {
        await api.delete('promocoes/' + id)
        window.location = '/promocoes'
      } catch (e) {
        console.log(e.response.data.message)
      }
    }
  }

  function edita(id) {
    window.location = 'promocao/edita/' + id
  }

  return (
    <div>
      <h1>Promoções</h1>
      <h2>Criar uma</h2>
      <form>
        <div className="form-group">
          <label>Procurar por Produto</label>
          <input onChange={(e) => setSearch(e.target.value)} type="text" className="form-control" id="produto" aria-describedby="emailHelp" placeholder=""></input>
        </div>
      </form>
      <button className="btn btn-dark" onClick={handleSubmit}>
        Procurar
      </button>
      <div className="container">
        <div className="row" id="busca-alvo">
          {produtos.map((p) => (
            <div className="col box text-center" key={p.id}>
              <p className=""> {p.title}</p>
              <img className="" src={p.image} alt={p.title} height="75" width="75"></img>
              <p className="text-center"> R$ {p.price.toFixed(2)}</p>
              <a className="btn btn-sm btn-primary my-3" id="criar" href={'promocao/nova/' + p.id}>
                Criar Promoção
              </a>
            </div>
          ))}
        </div>
      </div>

      <h3>Promoções</h3>
      {promo.map((p) => (
        <>
          <div key={p._id} className="alert alert-primary">
            <div className="container">
              <div className="row">
                <div className="col-8">
                  <h4>{p.name}</h4>
                  { p.type === 'cashback' && <p>Crédito de R$ {p.cashback} ao cliente</p> }
                  { p.type === 'freeProduct' && <p>Ao comprar {p.quantityRequired} pode-se pegar {p.quantityFree} grátis</p> }
                  <p>
                    Válido de {new Date(p.validFrom).toLocaleString('pt-BR')} até {new Date(p.validUntil).toLocaleString('pt-BR')}{' '}
                  </p>
                </div>
                <div className="col-4">
                  <button className="btn btn-warning mx-4" id="editar" onClick={() => edita(p._id)}>
                    Editar
                  </button>
                  <button className="btn btn-danger mx-4" id="apagar" onClick={() => apagar(p._id)}>
                    Apagar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  )
}
