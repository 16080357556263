import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import api from '../services/api'
import Modal from '../components/Modal'

import LoaderButton from '../components/LoaderButton'

import { getUserCredits, getUserComanda } from '../helpers/Users'

export default function GuardaVolumes() {
  const [comanda, setComanda] = useState('')
  const [feed, setFeed] = useState([])
  const [modal, setModal] = useState({})
  const [user, setUser] = useState({})
  const [isLoadingP, setIsLoadingP] = useState(false)
  const [isLoadingG, setIsLoadingG] = useState(false)
  const [tagMessage, setTagMessage] = useState('')

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line
  }, [])

  async function onLoad() {
    if (feed.length === 0) {
      const f = await api.get('feed/me')
      setFeed(f.data)
    }
  }

  async function pesquisa() {
    setIsLoadingP(true)
    try {
      const res = await api.get(`credit/user/tag/${comanda}`)
      if (res.data.tag) {
        setUser(res.data)
        setTagMessage('')
      } else {
        setModal({
          show: true,
          type: 'danger',
          title: 'Falha',
          msg: 'Comanda nao encontrada',
          link: '/guardavolumes',
          refresh: true
        })
      }

      setIsLoadingP(false)
    } catch (e) {
      setTagMessage('Comanda não encontrada')
      setIsLoadingP(false)
    }
    setIsLoadingP(false)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setIsLoadingG(true)
    const bora = window.confirm('Tem certeza?')
    if (bora) {
      setModal({
        show: true,
        type: 'warning',
        title: 'Processando',
        msg: 'Aguarde...'
      })

      try {
        const gv = await api.get('/product/search/guarda')
        if (gv.data.length !== 1) {
          setModal({
            show: true,
            type: 'danger',
            title: 'Falha',
            msg: 'Problemas em encontorar o Guarda Volumes no cardápio, chame o ADM',
            link: '/guardavolumes',
            refresh: true
          })
          setIsLoadingG(false)
        }
        const product = {
          ...gv.data[0],
          barName: 'guardaVolumes',
          itemTotal: 15,
          reason: 'Vendido via guardaVolumes',
          stock: 10,
          quantity: 1
        }
        await api.post(`product/sell`, {
          productsArray: [product],
          userId: user.userId._id
        })
        setModal({
          show: true,
          type: 'success',
          title: 'Sucesso',
          msg: 'Deu tudo certo!',
          link: '/guardavolumes',
          refresh: true
        })
        setIsLoadingG(false)
      } catch (e) {
        console.log(e)
        setModal({
          show: true,
          type: 'danger',
          title: 'Falha',
          msg: JSON.stringify(e.response.message),
          link: '/guardavolumes',
          refresh: true
        })
        setIsLoadingG(false)
      }

      setIsLoadingG(false)
    }
    setIsLoadingG(false)
  }
  return (
    <div>
      <Modal modal={modal} />
      <h1>Guarda Volumes</h1>
      <form className="text-center my-5">
        <h3>Número da comanda</h3>
        <input type="text" className="form form-control" onChange={(e) => setComanda(e.target.value)}></input>
        <div>
          <LoaderButton isLoading={isLoadingP} onClick={pesquisa} className="btn btn-lg btn-success my-3">
            Pesquisar
          </LoaderButton>
        </div>
      </form>

      {tagMessage !== '' ? <div className="alert alert-warning">{tagMessage}</div> : <></>}

      {user.tag ? <GuardaVolumesUserInfo isLoadingG={isLoadingG} handleSubmit={handleSubmit} user={user} /> : <></>}

      <hr></hr>
      <h2>Últimas saídas</h2>
      <div id="target" className="alert alert-success">
        <ul>
          {feed.map((f) => (
            <>
              <li key={f._id.toString()} className="my-1">
                {new Date(f.createdAt).toLocaleString('pt-BR')} - {f.description}
              </li>
            </>
          ))}
        </ul>
      </div>
    </div>
  )
}

function GuardaVolumesUserInfo({ isLoadingG, handleSubmit, user }) {
  return (
    <div>
      <div>
        <h4>Informações</h4>
        <p>Nome: {user.userId.name}</p>
        <p>Saldo: {user.remainingAmount}</p>
        <p>Comanda: {user.tag}</p>
      </div>
      <div>
        <LoaderButton isLoading={isLoadingG} onClick={handleSubmit} className="btn btn-lg btn-warning my-3">
          Guardar!
        </LoaderButton>
      </div>
    </div>
  )
}

GuardaVolumesUserInfo.propTypes = {
  isLoadingG: PropTypes.bool,
  handleSubmit: PropTypes.func,
  user: PropTypes.object
}
