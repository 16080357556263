import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import api from '../services/api'
import { getUserComanda } from '../helpers/Users'
import ListUserInfo from '../components/Users/ListUserInfo'

export default function DesfazerVenda() {
  const params = useParams()

  const [item, setItem] = useState({})
  const [consumption, setConsumption] = useState({products: []})

  function voltar() {
    window.location = '/'
  }
  async function refazer() {
    try {
      await api.put(`consumption/redo`, {consumptionId: params.usageId})
      alert('Operação efetuada com sucesso')
      window.location = '/bar'
    } catch (e) {
      alert(e.response.data.error)
      window.location = '/bar'
    }
  }

  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad() {
    try {
      const res = await api.get(`consumption/${params.usageId}`)
      setConsumption(res.data)
      setItem(res.data.credit_usage.find((x) => x._id === params.usage_id))
    } catch (e) {
      console.log(e.response)
    }
  }
  return (
    <div className="Venda">
      <h1>Refazer a venda</h1>
      <div className="lander">
        <p className="text-muted">Tem certeja que deseja refazer a venda desse produto?</p>
        <div id="target">
          <div className="text-center">
            <h2>Detalhes do Item</h2>
            { consumption.products.map((product) => (
              <div key={product._id}>
                <h3>{product.title}</h3>
                <p>Quantidade: {product.quantity}</p>
                <h3>Valor: R$ {product.price * product.quantity},00</h3>
                <hr />
              </div>
            ))}
      
            Lembre que o valor total será debitado de volta a comanda {consumption.userTag}
            <hr />
          </div>
          
        </div>
        <div className='d-flex justify-content-center align-items-center'>
        <ListUserInfo userId={consumption.userId} />
          </div>
      </div>
      <div className="text-center">
        <button onClick={voltar} className="btn btn-success btn-lg bt">
          Voltar
        </button>{' '}
        |{' '}
        <button onClick={refazer} className="btn btn-danger btn-lg bt">
          Sim
        </button>
      </div>
    </div>
  )
}
