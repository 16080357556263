import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { FiActivity } from 'react-icons/fi'
import { dinheiro } from '../helpers/Formata'

import { getRole } from '../services/auth'

import { getByClient } from '../helpers/Users'
import { getConsumptionByUser } from '../services/consumption'

export default function ListaUserPedidos({ userId, parcial }) {
  const [consumptions, setConsumptions] = useState([])

  function filterLast12Hours(objects) {
    const now = Date.now()
    const twelveHoursAgo = now - 12 * 60 * 60 * 1000
    return objects.filter((obj) => new Date(obj.createdAt).getTime() >= twelveHoursAgo)
  }

  useEffect(() => {
    getConsumptionByUser(userId).then((data) => {
      setConsumptions(filterLast12Hours(data))
    })
  }, [userId])

  if (userId === undefined) {
    return <></>
  }
  return (
    <div>
      {consumptions.map((p) => (
        <div key={p.usage_id} className="row row-main" style={{ textDecoration: p.isValid ? '' : 'line-through' }}>
          <div className="col-6">
            <div className="row d-flex">
              {p.products.map((i) => (
                <p key={i._id}>
                  <b>
                    {i.title} ({i.quantity})
                  </b>
                </p>
              ))}
            </div>
            <div className="row d-flex">
              <p className="text-muted">Vendido por {p.adminName.split(' ')[0]}</p>
            </div>
            {parcial ? (
              <></>
            ) : (
              <div className="row d-flex">
                <Link to={`${p.isValid ? '' : '#'}/bar/refazer/${userId}/${p._id}`}>
                  <button className="btn btn-sm btn-success">Refazer</button>
                </Link>
              </div>
            )}
          </div>
          <div className="col-6">
            <div className="row d-flex justify-content-end">
              {p.products.map((i) => (
                <p key={i._id}>
                  <b>R$ {dinheiro(i.price * i.quantity)}</b>
                </p>
              ))}
            </div>
            <div className="row d-flex  justify-content-end">
              <p className="text">{new Date(p.createdAt).toLocaleString('pt-BR')}</p>
            </div>
            {parcial ? (
              <></>
            ) : (
              <div className="row d-flex justify-content-end">
                {!getRole().includes('Admin') ? (
                  <></>
                ) : (
                  <Link to={`${p.isValid ? '' : '#'}/bar/desfazer/${userId}/${p._id}`}>
                    <button className="btn btn-sm btn-danger">Desfazer</button>
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className=" my-4  m text-center">
            <FiActivity />
          </div>
        </div>
      ))}
    </div>
  )
}
