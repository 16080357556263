import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../services/api'
import LoaderButton from '../components/LoaderButton'
import CaixaBalanco from '../components/CaixaBalanco'
import CaixaRelatorioBasico from '../components/CaixaRelatorioBasico'
import Modal from '../components/Modal'
import { getCashRegisterById, getLastRegisterTransactionsById } from '../services/caixa'

export default function CaixaAdicionar() {
  const [caixa, setCaixa] = useState({ transactions: [] })
  const [adicionar, setAdicionar] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const [modal, setModal] = useState({})

  useEffect(() => {
    getCaixa(params.id)
    // eslint-disable-next-line
  }, [])

  function getRegisterTotal(totals) {
    let total = 0
    for (const key in totals) {
      total += totals[key]
      console.log(totals[key])
    }
    return total
  }

  async function getCaixa(id) {
    const res = await getCashRegisterById(id)
    if (res) {
      setCaixa(res.data)
    } else {
      setCaixa({ transactions: [] })
    }
  }

  async function handleSubmit() {
    setIsLoading(true)
    setModal({
      show: true,
      type: 'warning',
      title: 'Tentando adicionar dinheiro',
      msg: 'Aguarde...'
    })
    try {
      await api.put('/cash-register/depletion/' + params.id, { value: parseInt(adicionar), paymentType: 'CASH' })
      setModal({
        show: true,
        type: 'success',
        title: 'Sucesso',
        msg: 'Dinheiro adicionado',
        link: '/caixaAdm'
      })
    } catch (e) {
      setIsLoading(false)
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: JSON.stringify(e.message),
        link: '/caixaAdm'
      })
    }
    setIsLoading(false)
  }
  return (
    <div>
      <Modal modal={modal} />
      <h1>Adicionar Dinheiro</h1>
      Valor
      <input
        className="form-control my-2"
        type="number"
        onChange={(e) => setAdicionar(e.target.value)}
        id="intRetirada"
      />
      <LoaderButton isLoading={isLoading} onClick={handleSubmit} className="btn btn-success">
        Adicionar Dinheiro
      </LoaderButton>
      <hr />
      <CaixaRelatorioBasico transactions={caixa.transactions} initialCashAmount={caixa.initialCashAmount} />
      <hr />
      Total em caixa: R$:{' '}
      {caixa.initialCashAmount + caixa.transactions.filter((c) => !c.depletion).reduce((acc, v) => acc + v.amount, 0)}
      <hr />
      <CaixaBalanco transactions={caixa.transactions} />
    </div>
  )
}
