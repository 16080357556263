import React from 'react'

export default function PaymentType({ payment, setPayment, id, name }) {
  return (
    <div>
      <div className="my-3">
        <div className="row text-center">
          <div className="col-5">Dinheiro</div>
          {/* <div className="col-4">Pix</div> */}
          <div className="col-5">Cartão</div>
        </div>
        <div className="row text-center" onChange={(e) => setPayment(e.target.value)}>
          <div className="col-5">
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id={`cbxDinheiro${id}`}
              value="CASH"
              checked={payment === 'CASH'}
            />
          </div>
          {/* <div className="col-4">
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id={`cbxPix${id}`}
              value="pix"
            />
          </div> */}
          <div className="col-5">
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id={`cbxCartao${id}`}
              value="CARD"
              checked={payment === 'CARD'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
