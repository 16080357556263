const moment = require('moment')

export function usoDeHoje(credit) {
  const res = []
  const ontem = moment().add('-1', 'days')
  for (const c of credit) {
    if (moment(c.created_at).format() > ontem.format()) {
      res.push(c)
    }
  }
  return res
}

export function getDeltaHour(startDate, endDate) {
  const start = moment(startDate)
  const end = moment(endDate)
  const duration = moment.duration(end.diff(start))
  const hours = duration.asHours()
  return hours.toFixed(2)
}
export function formatCreationDate(date) {
  const now = new Date()
  const duration = now.getTime() - date.getTime() // duração em milissegundos

  const seconds = Math.floor(duration / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)

  if (years > 0) {
    return `${years} ano${years > 1 ? 's' : ''}`
  } else if (months > 0) {
    return `${months} mês${months > 1 ? 'ses' : ''}`
  } else if (weeks > 0) {
    return `${weeks} semana${weeks > 1 ? 's' : ''}`
  } else if (days > 0) {
    return `${days} dia${days > 1 ? 's' : ''}`
  } else if (hours > 0) {
    return `${hours} hora${hours > 1 ? 's' : ''}`
  } else {
    return `menos que uma hora`
  }
}

export function formatFutureDuration(date) {
  const now = new Date()
  const duration = date.getTime() - now.getTime() // duration in milliseconds

  if (duration < 0) {
    return 'The provided date is in the past'
  }

  const seconds = Math.floor(duration / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)

  if (years > 0) {
    return `${years} ano${years > 1 ? 's' : ''}`
  } else if (months > 0) {
    return `${months} mês${months > 1 ? 'ses' : ''}`
  } else if (weeks > 0) {
    return `${weeks} semana${weeks > 1 ? 's' : ''}`
  } else if (days > 0) {
    return `${days} dia${days > 1 ? 's' : ''}`
  } else if (hours > 0) {
    return `${hours} hora${hours > 1 ? 's' : ''}`
  } else {
    return `menos que uma hora`
  }
}

// Usage example
const futureDate = new Date('2024-12-31T00:00:00')
