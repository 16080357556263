import React, { useEffect, useState } from 'react'
import api from '../services/api'
import { getId, getRole, adminSelectClient } from '../services/auth'
import Modal from '../components/Modal'
import Loading from '../components/Loading'
import { getLoggedAdminInfo } from '../services/admin'

export default function Selecao() {
  const [user, setUser] = useState({ client: [] })
  const [modal, setModal] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    onLoad()
    async function onLoad() {
      if (getRole().includes('Operator')) {
        if (process.env.REACT_APP_KITNET_V2 === 'true') {
          const admin = await getLoggedAdminInfo()

          setUser({ ...admin, client: admin.clients })
          setIsLoaded(true)
        } else {
          const u = await api.get(`users/${getId()}`)
          setUser(u.data)
          setIsLoaded(true)
        }
      } else {
        window.location = '/'
      }
    }
    // eslint-disable-next-line
  }, [])

  async function selectClient(clientId) {
    const ctz = window.confirm('Tem certeza que é esse?')
    if (ctz) {
      setModal({
        show: true,
        type: 'warning',
        title: 'Aguarde',
        msg: 'Selecionando cliente',
        loading: true
      })
      try {
        await adminSelectClient(clientId)

        setModal({
          show: true,
          type: 'success',
          title: 'Sucesso',
          msg: 'Selecionado com sucesso',
          link: '/'
        })
      } catch (e) {
        setModal({
          show: true,
          type: 'danger',
          title: 'Erro',
          msg: JSON.stringify(e.response.data.error),
          link: '/'
        })
      }
    }
  }

  return isLoaded ? (
    <div>
      <Modal modal={modal} />
      <h1>Selecione</h1>
      <p></p>

      <div className="text-center">
        {user.client
          .filter((c) => c.role !== 'User')
          .map((e) => (
            <div key={e.id} className="m-2">
              <button id={e.name} className="btn btn-primary" onClick={() => selectClient(e.id)}>
                {e.name}
              </button>
              <hr />
            </div>
          ))}
      </div>
    </div>
  ) : (
    <Loading size={50} />
  )
}
