import { useState } from 'react'
import api from '../../services/api'
import ListaUserPedidos from '../../components/ListaUserPedidos'
import ListaUserRecargas from '../../components/ListaUserRecargas'

export default function AdmCreditos() {
  const [tag, setTag] = useState('')
  const [error, setError] = useState('')
  const [credit, setCredit] = useState({ userId: {}, transactions: [], tag: '' })
  const [value, setValue] = useState(0)

  async function handleClick() {
    try {
      const res = await api.get(`credit/user/tag/${tag}`)
      setCredit(res.data)
      setError('')
    } catch (e) {
      console.log(e)
      setError('Comanda não encontrada')
    }
  }

  async function addCredit() {
    if (parseInt(value, 10) > 100 || parseInt(value, 10) < 0) {
      alert('Valor nao pode ser maior que 100')
      return
    }
    try {
      const ctz = window.confirm('Tem certeza?')
      if (ctz) {
        const res = await api.post(`credit`, {
          userId: credit.userId._id,
          tag: credit.tag,
          amount: parseInt(value, 10)
        })
        alert('Creditos adicionados')
        window.location = '/'
      }
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div>
      <h1>AdmCreditos</h1>
      <p>Use essa página para dar créditos sem passar pelo caixa.</p>
      <p>Use para staff ou promoções.</p>
      <div>
        <div className="container">
          <div className="">
            <div className="col">Comanda</div>
            <div className="col">
              {' '}
              <input
                className="form form-control"
                type="text"
                placeholder="123"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </div>
            <div className="">
              <button className="btn btn-primary mx-5" onClick={handleClick}>
                Procurar
              </button>
              <button
                className="btn btn-primary mx-5"
                onClick={() => {
                  setCredit({ userId: {}, transactions: [], tag: '' })
                  setTag('')
                }}
              >
                Limpar
              </button>
            </div>
          </div>
        </div>
      </div>
      {error !== '' && <div>{error}</div>}
      {tag !== '' && credit && credit.tag && credit.tag !== '' && (
        <div>
          <hr />
          <p>Deseja adicionar créditos</p>
          <div className="">
            <div className="">
              <div className="col">Valor</div>
              <div className="col">
                <input
                  className="my-2 mx-2 form form-control"
                  type="text"
                  placeholder="0.00"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="my-2 mx-2">
                <button className="btn btn-warning mx-5" onClick={addCredit}>
                  Adicionar
                </button>
              </div>
            </div>
          </div>
          <h2>{credit.userId.name}</h2>
          <ListaUserPedidos userId={credit.userId._id} />
          <hr />
          <p>Recargas</p>
          <ListaUserRecargas user_id={credit.userId._id} recargas={credit.transactions} />
        </div>
      )}
    </div>
  )
}
