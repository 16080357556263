import React from 'react';
import { Card } from 'react-bootstrap';

const ListUserInfo = ({ userId }) => {
  if (!userId) return null;
  return (
    <Card style={{ width: '18rem' }} className='my-5'>
      <Card.Body>
        <Card.Title>Informações do Usuário</Card.Title>
        <Card.Text>
          <strong>Nome:</strong> {userId.name} <br />
          <strong>Documento:</strong> {userId.doc} <br />
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ListUserInfo;
