import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import LoaderButton from './LoaderButton'
import { dinheiro, telefone } from '../helpers/Formata'
import { usoDeHoje } from '../helpers/Datas'

import Modal from './Modal'

import api from '../services/api'

import FilaDePedidos from './FilaDePedidos'
import ListaUserPromo from './ListaUserPromo'
import ListaUserPedidos from './ListaUserPedidos'

import { getUserCredits } from '../helpers/Users'

export default function Comanda({ setComanda, comanda, setUser }) {
  const [pedidos, setPedidos] = useState([])
  const [modal, setModal] = useState({})
  const [error, setError] = useState({})

  // const [user, setUser] = useState('')
  const user = { credit_usage: [], promo: [] }
  useEffect(() => {
    getPedidosPendentes()
  }, [])

  const [isLoading, setIsLoading] = useState(false)
  const [limparPedidosLoading, setLimparPedidosLoading] = useState(false)

  async function getPedidosPendentes() {
    const pendentes = await api.get('deliveryQueue/mine')
    setPedidos(pendentes.data)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setError({})
    setIsLoading(true)
    setModal({
      show: true,
      type: 'warning',
      title: 'Procurando comanda',
      loading: true,
      msg: 'Aguarde...'
    })
    try {
      const res = await api.get(`credit/user/tag/${comanda}`)
      if (res.data) {
        setComanda(res.data.tag)
        setUser(res.data)
        setComanda('')
        setModal({
          show: true,
          type: 'success',
          title: 'Sucesso',
          msg: 'Achei!',
          loading: false
        })
      } else {
        setModal({
          show: true,
          type: 'danger',
          title: 'Não achei',
          msg: `Comanda ${comanda} não associada a nenhum usuário`,
          link: '/bar',
          refresh: true,
          loading: false
        })
        setError({
          message: 'Comanda não encontrada, por favor procure de novo'
        })
      }
    } catch (e) {
      console.log(e)
      if (e.response) {
        if (e.response.status === 401) {
          window.location = '/'
        }
        setModal({
          show: true,
          type: 'danger',
          title: 'Não achei',
          msg: `Comanda ${comanda} não associada a nenhum usuário`,
          link: '/bar',
          loading: false
        })
      } else {
        window.location = '/'
      }
    }
    setIsLoading(false)
    // x.innerHTML = html
  }

  async function limparMeusPedidos() {
    const ctz = window.confirm('Tem certeza?')
    if (!ctz) {
      return null
    }
    setLimparPedidosLoading(true)
    try {
      await api.post('/deliveryQueue/clearMyQueue')
      setPedidos([])
      setLimparPedidosLoading(false)
    } catch (e) {
      console.log(JSON.stringify(e.response.data))
    }
    setLimparPedidosLoading(false)
  }

  function validateForm() {
    return comanda && comanda.length > 0
  }

  return (
    <div className="comanda">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="comanda">
          <Form.Label> Comanda </Form.Label>{' '}
          <Form.Control autoFocus type="number" value={comanda} onChange={(e) => setComanda(e.target.value)} />{' '}
        </Form.Group>{' '}
        <LoaderButton
          id="buscaComanda"
          block
          // size="lg"
          type="submit"
          isLoading={isLoading}
          className="my-2 w-100 btn-hight-40"
          disabled={!validateForm()}
        >
          Procurar
        </LoaderButton>{' '}
      </Form>

      {error.message != undefined && (
        <div className="card bg-warning m-2 p-1">
          <div className="card-header">Problemas</div>
          <div className="card-body">
            <p className="card-text">{error.message}</p>
          </div>
        </div>
      )}
      <div className="target" id="fila">
        <hr />
        <h3>
          Fila de Pedidos |
          <LoaderButton isLoading={limparPedidosLoading} onClick={limparMeusPedidos} className="btn btn-warning m-2">
            Concluir Todos
          </LoaderButton>
        </h3>
        <FilaDePedidos pedidos={pedidos} setPedidos={setPedidos} />
      </div>
    </div>
  )
}
