import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export default function DeleteAdminModal({ show, onHide, onDelete, adminName }) {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Exclusão</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {adminName
          ? `Tem certeza de que deseja excluir o administrador "${adminName}"?`
          : 'Tem certeza de que deseja excluir este administrador?'}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={onDelete}>
          Excluir
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
