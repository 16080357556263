import { CaixaDetalhesHistorico } from './../components/CaixaDetalhesHistorico'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../services/api'
import CaixaBalanco from '../components/CaixaBalanco'
import { formatToBRL } from '../helpers/Numbers'

export default function CaixaDetalhes() {
  const [caixa, setCaixa] = useState({ entradas: [], saidas: [], history: [], transactions: [] })
  const [caixaHistorico, setCaixaHistorico] = useState([])
  const params = useParams()

  useEffect(() => {
    getCaixa(params.id)
    // eslint-disable-next-line
  }, [])
  // useEffect(() => {
  //   getCaixaHistory(params.id)
  //   // eslint-disable-next-line
  //     }, [])

  async function getCaixa(id) {
    const res = await api.get('cash-register/' + id)
    setCaixa(res.data)
  }
  return (
    <div>
      <h2>Detalhes de Caixa</h2>
      <h3>{caixa.name}</h3>
      <hr />
      <CaixaBalanco transactions={caixa.transactions} />
      <hr />
      {/* <CaixaDetalhesHistorico u={caixa.history} /> */}
      {/* <CaixaDetalhesHistorico u={caixaHistorico} /> */}
    </div>
  )
}
