import React, { useState, useEffect } from 'react'
// import { useAppContext } from "../lib/contextLib";
import { useParams } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
// import api from '../services/api'

import './Usuario.css'
import UserForm from '../components/Users/UserForm'
import { createUser, deleteUserById, getUserById, updateUserById } from '../services/users'

export default function Usuario() {
  const params = useParams()
  const [userId, setUserId] = useState(params.id)
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [modalTitle, setModalTitle] = useState('')

  const [user, setUser] = useState({
    name: '',
    document: '',
    email: '',
    birth: '',
    photo: '',
    phone: ''
  })

  useEffect(() => {
    if (params.id) {
      getUserById(params.id).then((user) => {
        setUserId(params.id)
        setUser({
          name: user.name,
          doc: user.doc,
          email: user.email,
          birth: user.birth ? new Date(user.birth) : '',
          photo: user.photo,
          phone: user.phone,
          blockedUntil: user.blockedUntil ? user.blockedUntil : false,
          createdAt: user.createdAt
        })
      })
    }
  }, [])

  const saveUser = (user, userId) => {
    if (userId) {
      updateUserById(user, userId)
        .then(() => {
          setShowModal(true)
          setModalTitle('Sucesso')
          setModalMessage('Atualizado com sucesso!')
        })
        .catch((error) => {
          setModalMessage(`Error saving admin:  ${error.response.data.error}`)
          setShowModal(true)
          setModalTitle('Falha')
        })
    } else {
      createUser(user)
        .then(() => {
          setShowModal(true)
          setModalTitle('Sucesso')
          setModalMessage('Criado com sucesso!')
        })
        .catch((error) => {
          setShowModal(true)
          setModalTitle('Falha')
          setModalMessage(`Error saving admin:  ${error.response.data.error}`)
          console.log('Error saving admin:', error)
        })
    }
  }

  function deleteUser(userId) {
    const ctz = window.confirm('Tem certeza que quer apagar?')
    if (ctz) {
      deleteUserById(userId)
        .then(() => {
          setShowModal(true)
          setModalTitle('Sucesso')
          setModalMessage('Usuário deletado com sucesso!')
        })
        .catch((error) => {
          setShowModal(true)
          setModalTitle('Falha')
          setModalMessage(`Erro deletando usuario:  ${error.response.data.error}`)
          console.log('Error saving admin:', error)
        })
    }
  }

  // function validateForm() {
  //   return (tel.length > 0 || cpf.length > 0) && nome.length > 0
  // }

  // async function handleSubmit(event) {
  //   event.preventDefault()
  //   setIsLoading(false)
  //   if (email === '') {
  //     setEmail(nome + Math.floor(Math.random() * 1000000) + '@kitnet.fake')
  //   }
  //   const nomecompleto = nome.split(' ')
  //   if (nomecompleto.length <= 1) {
  //     alert('Por favor, corriga o nome')
  //     setIsLoading(false)
  //     return null
  //   }

  //   const primeiro = nomecompleto.shift()

  //   const sobrenome = nomecompleto.join(' ')
  //   try {
  //     const formData = {
  //       name: primeiro,
  //       lastname: sobrenome,
  //       email,
  //       rg,
  //       cpf,
  //       birth: new Date(birth),
  //       tel,
  //       password: senha,
  //       role
  //     }

  //     await api.post('users/admin', formData)
  //     alert('Usuário criado com sucesso')
  //     window.location = '/'
  //   } catch (e) {
  //     alert('Erro ao criar o cadastro:\n\n' + e.response.data.message)
  //   }
  //   setIsLoading(false)
  // }

  return (
    <div className="container">
      <h1> Usuário </h1>
      <UserForm userData={user} onSave={saveUser} userId={userId} deleteUser={deleteUser} />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" href="/usuarios">
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        <Button variant="btn btn-success" href="/usuarios">
          Voltar
        </Button>
      </div>
    </div>
  )
}
