import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import api from '../services/api'

import CaixaBalanco from '../components/CaixaBalanco'
import LoaderButton from '../components/LoaderButton'
import Modal from '../components/Modal'

import { getRole } from '../services/auth'
import Loading from '../components/Loading'

export default function CaixaFechar() {
  const [caixa, setCaixa] = useState({})
  const [valor, setValor] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [modal, setModal] = useState(false)
  const [cartao, setCartao] = useState('')
  const [pageLoaded, setPageLoaded] = useState(false)

  const params = useParams()
  useEffect(() => {
    onLoad().then(() => setPageLoaded(true))
    // eslint-disable-next-line
  }, [])

  async function onLoad() {
    const res = await api.get('cash-register/' + params.id)
    setCaixa(res.data)
  }

  async function handleSubmit() {
    const link = params.from ? '/' + params.from : '/'

    setIsLoading(true)
    const ctz = window.confirm('Tem certeza que quer fechar o Caixa?')
    if (ctz) {
      // if(parseInt(valor, 10) !== (caixa.entrada - caixa.saida + caixa.valorInicial) && getRole() !== "Admin"){
      //   setModal(true)
      //   setTitulo('Problemas')
      //   setTipo('modal-danger')
      //   setMsg('O valor informado não bate com o valor esperado :( | Chame um Admin ou tente novamente')
      //   setLink(`/`)
      // }else {
      try {
        setModal({
          show: true,
          type: 'warning',
          title: 'Trabalhando',
          msg: 'Aguarde...',
          loading: true
        })
        await api.post('cash-register/close', {
          cashRegisterId: params.id,
          closingCashAmount: parseInt(valor, 10),
          closingCardAmount: parseInt(cartao, 10)
        })
        setModal({
          show: true,
          type: 'success',
          title: 'Sucesso',
          msg: 'Caixa Fechado com sucesso',
          link: link
        })
      } catch (e) {
        alert('Algo deu Errado')
        alert(JSON.stringify(e.response.message))
        setIsLoading(false)
      }
    }

    // }

    setIsLoading(false)
  }

  function validate() {
    return valor >= 0
  }

  if (!pageLoaded) {
    return <Loading size={50} />
  }

  return (
    <div>
      <h2>Fechando Caixa</h2>
      <Modal modal={modal} />
      <p>
        Valor em Caixa (Dinheiro):
        <input className="form-control" onChange={(e) => setValor(e.target.value)} type="number" id="valorFinalCaixa" />
      </p>
      <p>
        Valor em Cartão (Que a máquina imprimiu):
        <input
          className="form-control"
          onChange={(e) => setCartao(e.target.value)}
          type="number"
          id="varlorFinalCartao"
        />
      </p>
      <LoaderButton
        id="btnFecharCaixa"
        isLoading={isLoading}
        disabled={!validate()}
        onClick={handleSubmit}
        className="btn btn-danger my-2"
      >
        Fechar Caixa
      </LoaderButton>

      <h3>{caixa.adminFullName}</h3>
      {getRole().includes('Admin') ? (
        <>
          <div className="container">
            <div className="row">
              <div className="col">Valor em Caixa (inicial + dinheiro)</div>
              <div className="col">
                R$:{' '}
                {caixa.initialCashAmount +
                  caixa.transactions
                    .filter((c) => c.type === 'CASH' && !c.depletion)
                    .reduce((acc, v) => acc + v.amount, 0) +
                  caixa.transactions
                    .filter((c) => c.type === 'CASH' && c.depletion && c.amount > 0)
                    .reduce((acc, v) => acc + v.amount, 0)}
              </div>
            </div>
            <div className="row">
              <div className="col">Valor Colocado (dinheiro + cartao)</div>
              <div className="col">
                R$:{' '}
                {caixa.transactions
                  .filter((c) => c.type === 'CARD' && c.depletion === false)
                  .reduce((acc, v) => acc + v.amount, 0)}
              </div>
            </div>
          </div>

          <hr />
          {/* <CaixaBalanco caixa={caixa} /> */}
        </>
      ) : (
        <>Caso deseje ver o histórico do caixa, chame um Admin</>
      )}
    </div>
  )
}
