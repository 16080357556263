import React, { useEffect, useState } from 'react'

import api from '../services/api'

import Loading from './Loading'

export default function Acoes() {
  const [feed, setFeed] = useState(false)

  useEffect(() => {
    getFeed()
  }, [])

  async function getFeed() {
    try {
      const feed = await api.get(`feed/me`)
      setFeed(feed.data)
    } catch (e) {
      alert(e.message)
    }
  }
  return (
    <div>
      <hr />
      <h4>Feed</h4>
      <div id="target" className="alert alert-success">
        <ul className="list-group">
          {feed ? (
            <>
              {feed.length === 0 ? (
                <>Nada ainda</>
              ) : (
                <>
                  {feed.map((f) => (
                    <span key={f._id.toString()}>
                      <li className="list-group-item">
                        {' '}
                        {new Date(f.createdAt).toLocaleString('pt-BR')} - {f.description}{' '}
                      </li>
                    </span>
                  ))}
                </>
              )}
            </>
          ) : (
            <Loading size={60} />
          )}
        </ul>
      </div>
    </div>
  )
}
