import React, { useState, useEffect } from 'react'
import api from '../services/api'

export default function Feed() {
  const [selectedUser, setSelectedUser] = useState('')
  const [users, setUsers] = useState([])
  const [feed, setFeed] = useState([])

  useEffect(() => {
    getFeed()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getFeed()
    // eslint-disable-next-line
  }, [selectedUser])

  useEffect(() => {
    getUsers()

    async function getUsers() {
      const res = await api.get('admin')
      setUsers(res.data)
    }
  }, [])

  async function getFeed() {
    const tg = document.getElementById('target')
    let r = ''
    if (selectedUser === '') {
      r = await api.get('feed')
    } else {
      r = await api.get('feed/admin/' + selectedUser)
    }
    setFeed(r.data)
  }
  return (
    <div>
      <h1>Feed</h1>
      <button onClick={getFeed} alt="refresh">
        Atualizar
      </button>
      <select onChange={(e) => setSelectedUser(e.target.value)} className="my-2 form-control">
        <option value="">Selecione</option>
        <option value="">----</option>
        {users.map((r) => (
          <option key={r._id} value={r._id}>
            {r.name} {r.lastname}
          </option>
        ))}
      </select>
      <div id="target">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Horário</th>
              <th scope="col">Quem?</th>
              <th scope="col">O que aconteceu?</th>
            </tr>
          </thead>
          <tbody>
            {feed.map((r) => (
              <tr key={r._id}>
                <td>{new Date(r.createdAt).toLocaleString('pt-BR')}</td>
                <td>{r.adminName}</td>
                <td>{r.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
