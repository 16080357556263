import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../services/api'

import Form from 'react-bootstrap/Form'
import ListaUserPedidos from '../components/ListaUserPedidos'
import ListaUserRecargas from '../components/ListaUserRecargas'
import LoaderButton from '../components/LoaderButton'
import ModalSucesso from '../components/ModalSucesso'

import { getRole, getFeatures } from '../services/auth'

import './Creditos.css'
import { usoDeHoje } from '../helpers/Datas'
import { getUserComanda, getUserCredits } from '../helpers/Users'
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai'
import PaymentType from '../components/Caixa/PaymentType'
import { getCreditPerUserId } from '../services/credit'
import { sumVailableCredits } from './../helpers/Credits'
import { transactionAndCredit } from '../services/caixa'

export default function Creditos() {
  const [valor, setValor] = useState(0)
  const [extraValue, setExtraValue] = useState(0)
  const [comanda, setComanda] = useState(0)
  const [creditos, setCreditos] = useState(0)
  const [usoCreditos, setUsoCreditos] = useState([])
  const [gastoCredito, setGastoCreditos] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [pagamento, setPagamento] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [document, setDocument] = useState('')

  const [modal, setModal] = useState(false)
  const [msg, setMsg] = useState('')
  const [titulo, setTitulo] = useState('')
  const [tipo, setTipo] = useState('')
  const [extraPaymentType, setExtraPaymentType] = useState('vazio')
  const [loading, setLoading] = useState(false)
  const [extraPaymentMethod, setExtraPaymentMethod] = useState(false)

  useEffect(() => {
    onLoad()
  }, [])

  const params = useParams()

  function adcPaymentMethod() {
    setExtraPaymentMethod(!extraPaymentMethod)
    setExtraPaymentType('vazio')
    setExtraValue(0)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setIsLoading(true)
    if (valor > 100 && !getFeatures().includes('PosPago')) {
      alert('Desculpe, mas R$ 100, é o limite por operação\nSe necessário, faça mais operações')
      alert('A ação não foi concluída, por favor, repita com um valor menor')
      setIsLoading(false)
      return 0
    }

    if (comanda < 16 && !getRole().includes('Admin')) {
      alert('Apenas Admin podem por créditos para comandas de 1 a 15')
      alert('A operação não foi executada')
      setIsLoading(false)
      return 0
    }
    const bora = window.confirm(
      'Tem certeza que deseja adicionar R$' + String(parseInt(valor) + parseInt(extraValue)) + '?'
    )
    if (bora) {
      setLoading(true)
      setModal(true)
      setTitulo('Trabalhando')
      setTipo('modal-warning')
      setMsg('Trabalhando na sua operação, aguarde ...')

      try {
        if (parseInt(extraValue) > 0) {
          await transactionAndCredit([
            {
              type: pagamento,
              amount: parseInt(valor, 10),
              userId: params.id,
              description: 'Via Caixa',
              tag: comanda
            },
            {
              type: extraPaymentType,
              amount: parseInt(extraValue, 10),
              userId: params.id,
              description: 'Via Caixa',
              tag: comanda
            }
          ])
        } else {
          await transactionAndCredit([
            {
              type: pagamento,
              amount: parseInt(valor, 10),
              userId: params.id,
              description: 'Via Caixa',
              tag: comanda
            }
          ])
        }
        setMsg('O valor de  R$' + String(parseInt(valor) + parseInt(extraValue)) + ',00 foi adicionado com sucesso!')
        setTitulo('Tudo certo')
        setLoading(false)
        setTipo('modal-success')
      } catch (e) {
        setModal(true)
        setTitulo('Erro')
        setTipo('modal-danger')
        setLoading(false)
        setMsg(`Dinheiro não adicionado. | ${JSON.stringify(e.response.data.error)}`)
      }
    }
    setIsLoading(false)
  }

  function validateForm() {
    return valor.length > 0 && pagamento.length > 0
  }

  async function onLoad() {
    try {
      getCreditPerUserId(params.id).then((res) => {
        setComanda(res.tag)
        setName(res.userId.name)
        setPhone(res.userId.phone)
        setDocument(res.userId.doc)
        setUsoCreditos(res.remainingAmount)
      })

      // const user = await api.get('users/' + params.id)
      // setCreditos(getUserCredits(user.data.credit))
      // setGastoCreditos(usoDeHoje(user.data.credit_usage.reverse()))
      // setUsoCreditos(usoDeHoje(user.data.credit_charge.reverse()))
      // setComanda(user.data.comanda)
    } catch (e) {
      alert(e)
      // window.location = '/'
    }
  }

  return (
    <div className="AssociarComanda">
      <h1> Operações de Crédito </h1>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{name}</h5>
          <h6 className="card-subtitle mb-2 text-muted">{phone}</h6>
          <h6 className="card-subtitle mb-2 text-muted">{document}</h6>
          <h6 className="card-subtitle mb-2">Comanda: {comanda}</h6>
          <p className="card-text">Créditos: R$ {usoCreditos},00</p>
        </div>
      </div>
      <hr />

      <ModalSucesso
        tipo={tipo}
        loading={loading}
        titulo={titulo}
        msg={msg}
        link={() => (window.location = '/caixaOperar')}
        show={modal}
        valor={parseInt(usoCreditos, 10) + parseInt(valor, 10)}
      />
      <div className="">
        <Form onSubmit={handleSubmit}>
          <Form.Group size="lg" controlId="comanda">
            <Form.Label>Valor</Form.Label>
            <Form.Control autoFocus type="number" size="lg" onChange={(e) => setValor(e.target.value)} />
          </Form.Group>
          <PaymentType id={''} payment={pagamento} setPayment={setPagamento} name="pamento" />
          <hr />
          <div className="text-center" onClick={adcPaymentMethod}>
            <p>Adicionar mais uma forma de pagamento</p>
            {extraPaymentMethod ? <AiOutlineMinusCircle size={30} /> : <AiOutlinePlusCircle size={30} />}
          </div>

          {extraPaymentMethod && (
            <>
              <Form.Group size="lg" controlId="comanda">
                <Form.Label>Valor</Form.Label>
                <Form.Control
                  autoFocus
                  type="number"
                  value={extraValue}
                  size="lg"
                  onChange={(e) => setExtraValue(e.target.value)}
                />
              </Form.Group>
              <PaymentType
                id={'extra'}
                payment={extraPaymentType}
                setPayment={setExtraPaymentType}
                name="extraPayment"
              />
            </>
          )}
          <hr />
          <LoaderButton
            className="my-2 w-100"
            isLoading={isLoading}
            block
            size="lg"
            type="submit"
            disabled={!validateForm()}
          >
            Adicionar
          </LoaderButton>
        </Form>
      </div>
      <hr />
      <div id="target"> </div>

      {/* <div className="container">
        <h3>Histórico de Recargas</h3>
        <ListaUserRecargas user_id={params.id} recargas={usoCreditos} />
      </div>
      <hr />
      <div className="container">
        <h3>Uso de Créditos</h3>
        <ListaUserPedidos pedidos={gastoCredito} parcial={true} />
      </div> */}
    </div>
  )
}
