import React from 'react'
import ListaUserRecargas from './ListaUserRecargas'

export default function RechargeHistory({ user }) {
  return user.userId && user.userId ? (
    <div className="container">
      <h3>Histórico de Recargas</h3>
      <ListaUserRecargas user_id={user.userId} recargas={user.transactions} />
    </div>
  ) : (
    <div>
      <h3 className="text-center">Ainda não recarregou hoje</h3>
      {}
    </div>
  )
}
