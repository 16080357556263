import React, { useEffect, useState, useRef } from 'react'
import './Home.css'

import { getClientId, getName, getRole } from '../services/auth'
import BarLinks from '../roles/BarLinks'
import CaixaLinks from '../roles/CaixaLinks'
import PortariaLinks from '../roles/PortariaLinks'
import GuardaVolumesLinks from '../roles/GuardaVolumesLinks'
import EstoqueLinks from '../roles/EstoqueLinks'
import { Link } from 'react-router-dom'
import Loading from '../components/Loading'

export default function Home() {
  const loadingRef = useRef(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    onLoad()

    function onLoad() {
      if (!loadingRef.current) {
        if (getRole().includes('Operator')) {
          window.location = '/selecao'
        } else {
          console.log('Foi')
        }
        loadingRef.current = true
      }

      setLoaded(true)
    }
  }, [])

  if (!loaded) {
    return <Loading size={50} />
  }

  return (
    <div className="Home ">
      <p className="text-muted">Bem vindo</p>
      <h3>{getName()}</h3>
      <div className="text-center">
        {getRole().includes('Bar') ? (
          <>
            <BarLinks />
          </>
        ) : (
          <p></p>
        )}
        {getRole().includes('Payment') ? (
          <>
            <CaixaLinks />
          </>
        ) : (
          <p></p>
        )}

        {getRole().includes('Host') && (
          <>
            <PortariaLinks />
          </>
        )}

        {getRole().includes('Keeper') && <GuardaVolumesLinks />}

        {getRole().includes('Admin') ? (
          <div className="">
            <hr />
            <div className="buttons-container">
              <p>
                <Link className="" to="/admCreditos">
                  <button className="btn btn-block btn-primary button">
                    <span className="button-font-size">Creditos</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/caixaAdm">
                  <button className="btn btn-block btn-danger button">
                    <span className="button-font-size">Caixa Admin</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/promocoes">
                  <button className="button btn btn-block btn-secondary ">
                    <span className="button-font-size">Promoções</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/eventos">
                  <button id="btnEventos" className="button btn btn-block btn-warning ">
                    <span className="button-font-size">Eventos</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/usuarios">
                  <button id="btnUsuarios" className="button btn btn-block btn-dark ">
                    <span className="button-font-size">Usuarios</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/pedidos">
                  <button id="btnVisualizarPedido" className="button btn btn-info btn-block ">
                    <span className="button-font-size">Visualizar Pedidos</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/relatorio">
                  <button id="btnRelatorios" className="btn btn-info btn-block button">
                    <span className="button-font-size">Relatórios</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/vendasPorBar">
                  <button id="btnVendasPorBar" className="button btn btn-warning ">
                    <span className="button-font-size">Vendas Por Bar</span>
                  </button>
                </Link>
              </p>
              <hr />
              <p>
                <Link className="" to="/bloqueados">
                  <button id="btnRelatorios" className="button btn btn-info btn-danger ">
                    <span className="button-font-size">Lista Blockeds</span>
                  </button>
                </Link>
              </p>
              <p>
                <Link className="" to="/feed">
                  <button id="btnRelatorios" className="button btn btn-info btn-secondary ">
                    <span className="button-font-size">Feed</span>
                  </button>
                </Link>
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}

        {getRole().includes('Stock') || getRole().includes('Admin') ? (
          <>
            <EstoqueLinks />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
