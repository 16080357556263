import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../services/api'

import Modal from '../components/Modal'

export default function CaixaTrocoInicial() {
  const [caixa, setCaixa] = useState({ entradas: [], saidas: [], history: [] })
  const params = useParams()
  const [modal, setModal] = useState({})
  const [valor, setValor] = useState(0)

  useEffect(() => {
    getCaixa(params.id)
  }, [params.id])

  async function getCaixa(id) {
    const res = await api.get('cash-register/' + id)
    setCaixa(res.data)
  }

  async function trocaValor() {
    setModal({
      show: true,
      type: 'warning',
      title: 'Carregando',
      msg: 'Efetuando sua operacão, aguarde...'
    })

    if (valor >= 0) {
      try {
        await api.put(`cash-register/initial-cash-amount`, {
          cashRegisterId: params.id,
          initialCashAmount: parseInt(valor, 10)
        })
        setModal({
          show: true,
          type: 'success',
          title: 'Sucesso',
          msg: 'Deu tudo certo!',
          link: '/caixaAdm'
        })
      } catch (e) {
        setModal({
          show: true,
          type: 'danger',
          title: 'Falhamos',
          msg: JSON.stringify(e.message),
          link: '/caixaAdm'
        })
      }
    } else {
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: 'Valor precisa ser maior que zero',
        link: '/caixaAdm'
      })
    }
  }

  return (
    <div>
      <Modal modal={modal} />
      <h1>Alterar Troco</h1>
      <h3>Caixa número: {caixa.name}</h3>
      <p>Modificando o Valor do Troco Inicial (valor em troco do caixa)</p>
      <h4>Valor Atual: R$ {caixa.initialCashAmount}</h4>
      <hr />
      <div>
        <div>Novo Valor:</div>
        <div>
          <input className="form form-control" onChange={(e) => setValor(e.target.value)} type="number"></input>
        </div>
        <div>
          <button onClick={trocaValor} className="my-2 btn btn-warning">
            Enviar
          </button>
        </div>
      </div>
    </div>
  )
}
