import React, { useEffect, useState, memo } from 'react'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { BsCreditCard } from 'react-icons/bs'
import api from '../services/api'
import Loading from './Loading'
import { getCashRegister } from '../services/caixa'

export default memo(function CaixaSimples({ caixa }) {
  if (!caixa) {
    return (
      <>
        <Loading size={70} />
      </>
    )
  }
  async function fecharCaixa() {
    const prompt = window.confirm('Você tem certeza que quer fechar o seu caixa?')
    if (prompt) {
      try {
        window.location = `/caixaFechar/${caixa._id}`
      } catch (e) {
        alert(JSON.stringify(e.message))
      }
    }
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-sm text-center">
            {caixa.name !== '' ? (
              <>
                <FaRegMoneyBillAlt size={26} /> R$
                <span data-cy="caixaDinheiro">
                  {caixa.transactions
                    ? caixa.transactions
                        .filter((c) => c.type === 'CASH' && c.depletion === false)
                        .reduce((acc, v) => acc + v.amount, 0)
                    : 0}
                  ,00
                </span>{' '}
                |
                <BsCreditCard size={26} /> R$
                <span data-cy="caixaCartao">
                  {caixa.transactions
                    ? caixa.transactions
                        .filter((c) => c.type === 'CARD' && c.depletion === false)
                        .reduce((acc, v) => acc + v.amount, 0)
                    : 0}
                  ,00
                </span>
                <br />
                <button id="btnFecharCaixa" onClick={fecharCaixa} className="btn btn-danger my-2">
                  Fechar Caixa {caixa.name}
                </button>
              </>
            ) : (
              <Loading size={60} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
