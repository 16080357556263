export const roles = [
  'Bar',
  'Payment',
  'PaymentPlus',
  'Entry',
  'Host',
  'Keeper',
  'Sympla',
  'Stock',
  'Report',
  'Admin',
  'SuperAdmin'
]

export const translations = {
  Bar: 'Bar',
  Payment: 'Caixa',
  PaymentPlus: 'CaixaPlus',
  Entry: 'Entrada',
  Host: 'Hostess',
  Keeper: 'Guarda Volumes',
  Sympla: 'Sympla', // Se isso for um nome próprio, talvez não precise traduzir
  Stock: 'Estoque',
  Report: 'Relatório',
  Admin: 'Administrador',
  SuperAdmin: 'Super Administrador'
}

export function translateList(list) {
  return list.map((item) => translations[item] || item)
}
