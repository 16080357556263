import React, { useState, useEffect, memo } from 'react'
import { getCurrentEvent } from '../../services/events'

export default memo(function EventStatus({ event }) {
  

  if (event.name === undefined) {
    return <div>Não há eventos nesse momento, você não pode colocar pessoas na fila</div>
  }
  return (
    <>
      <div key={event.id} className="alert alert-light my-4">
        <div className="container">
          <div className="row">
            <div className="col-8">
              <h4>{event.name}</h4>
              <p>
                Começa em {new Date(event.start).toLocaleString('pt-BR')} e termina em{' '}
                {new Date(event.end).toLocaleString('pt-BR')}{' '}
              </p>
              <p>Valor Consumação: R$: {event.consumptionValue}</p>
              <p>Valor Entrada: R$: {event.entryValue}</p>
              <p>Limite de Pessoas: {event.peopleLimit}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})
