import React, { useState, useEffect } from 'react'

import Loading from '../../components/Loading'
import api from '../../services/api'

export default function AdmEventos({ setSelectedEvent }) {
  const [events, setEvents] = useState([])
  const [loadedEvents, setLoadedEvents] = useState(false)

  useEffect(() => {
    try {
      api.get('events').then((e) => {
        setEvents(e.data)
        setLoadedEvents(true)
      })
    } catch (error) {
      console.log(error)
    }
  }, [])

  function handleSelect(value) {
    if (value === '') {
      return
    }
    setSelectedEvent(events.find((event) => event.id === value))
  }
  return (
    <div>
      {!loadedEvents ? (
        <Loading size={60} />
      ) : (
        <div className="my-3">
          Selecione o evento
          <select className="form-control" onChange={(e) => handleSelect(e.target.value)}>
            <option value="">Selecione</option>
            <option value="">---------------</option>
            {events.map((e) => (
              <option key={e.id} value={e.id}>
                {new Date(e.start).toLocaleDateString('pt-BR')} - {e.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  )
}
