import api from './api'

export async function getConsumptionByUser(userId) {
  try {
    const response = await api.get('consumption/user/' + userId)
    return response.data
  } catch (error) {
    console.error(error)
    return []
  }
}

export async function getConsumptionById(id) {
  try {
    const response = await api.get('consumption/' + id)
    return response.data
  } catch (error) {
    console.error(error)
    return []
  }
}
