import React from 'react'

import { BsFillTelephoneFill } from 'react-icons/bs'
import moment from 'moment'
import { telefone } from '../../helpers/Formata'

export default function UsersQueue({queue}) {
    return (
      <div>
        {queue.map((u) => (
          <p className="text-center" key={u.id}>
            <a className="m-1 p-1 box" href={`portaria/${u._id}`}>
              <span
                id={u.userPhone}
                className={`text-decoration-underline ${u.obs.toLowerCase().includes('vip') ? 'text-vip' : ''}`}
              >
                {u.userName} - <BsFillTelephoneFill size={20} className="mx-1" /> {telefone(u.userPhone.slice(-4))} |{' '}
                {moment(new Date(u.created)).format('HH:mm')}
                {u.obs.toLowerCase().includes('vip') ? ' - VIP' : ''}
              </span>
            </a>
          </p>
        ))}
      </div>
    )
} 