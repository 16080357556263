import React, { useState, useEffect } from 'react'

import { BsSearch } from 'react-icons/bs'
import api from '../services/api'
import Loading from '../components/Loading'
import { getUserRole } from '../helpers/Users'
import { getNumberOfUsers, searchUserByText } from '../services/users'

export default function Usuarios() {
  const [admin, setAdmin] = useState([])
  const [userSearch, setUserSearch] = useState([])
  const [searchText, setSearchText] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [totalUsers, setTotalUsers] = useState(0)
  const [filterAdmin, setFilterAdmin] = useState('')
  const [users, setUsers] = useState([])

  async function getAdmin() {
    const u = await api.get('admin')
    setIsLoading(false)
    setAdmin(u.data)
    const total = await getNumberOfUsers()
    setTotalUsers(total)
  }

  async function procuraUsuarios() {
    setUsers([])
    const u = await searchUserByText(searchText)
    setUsers(u)
  }

  useEffect(() => {
    if (searchText.length > 2) {
      procuraUsuarios()
    }
  }, [searchText])

  useEffect(() => {
    getAdmin()
  }, [])

  return (
    <div>
      <h1>Administradores</h1>
      <div>
        {isLoading && <Loading size={25} />}
        <div>
          <input type="text" className="form form-control" onChange={(e) => setFilterAdmin(e.target.value)} />
        </div>
        <ul>
          {filterAdmin !== '' ? (
            <>
              {' '}
              {admin
                .filter((a) => a.name.toLowerCase().startsWith(filterAdmin.toLowerCase()))
                .map((u) => (
                  <li className="my-2" key={u._id}>
                    <a href={'admin/' + u._id}>{u.name}</a>
                    <p>{getUserRole(u)}</p>
                  </li>
                ))}
            </>
          ) : (
            <>
              {admin.map((u) => (
                <li className="my-2" key={u._id}>
                  <a href={'admin/' + u._id}>{u.name}</a>
                  <p>{getUserRole(u)}</p>
                </li>
              ))}
            </>
          )}
        </ul>
      </div>
      <div className="text-center my-5 p-2">
        <a href="/usuario" id="btnNovoUsuario" className="btn btn-primary">
          Criar um novo usuário
        </a>{' '}
        |{' '}
        <a href="/admin" id="btnNewAdmin" className="btn btn-danger">
          Criar um novo Administrador
        </a>
      </div>

      <h2>Pesquisar Usuários ({totalUsers})</h2>
      <div>
        <p>Digite o nome, telefone, sobrenome ou até mesmo comanda</p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-10">
            <input
              type="text"
              id="busca"
              className="form-control"
              onChange={(e) => setSearchText(e.target.value)}
            ></input>
          </div>
          <div className="col-2">
            <button onClick={procuraUsuarios} id="buscaUsuarios" type="button" className="btn btn-primary">
              <BsSearch />
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div id="my-4">
        <ul>
          {users.map((k) => (
            <li key={k._id}>
              <a id={`u-${k.doc}`} href={'usuario/' + k._id}>
                {k.name}
              </a>{' '}
              {k.phone} - {k.doc}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
