import { AddingCredits } from './Caixa/AddingCredits'
import { PayingCredits } from './Caixa/PayingCredits'
import React, { useState, memo } from 'react'
import Form from 'react-bootstrap/Form'
import LoaderButton from './LoaderButton'
import { dinheiro, telefone } from '../helpers/Formata'
import ListaUserPedidos from './ListaUserPedidos'

import { getFeatures } from '../services/auth'

import api from '../services/api'

import { getUserCredits } from '../helpers/Users'
import RechargeHistory from './RechargeHistory'
import { Link } from 'react-router-dom'
import { getCreditPerTag } from '../services/credit'

export default memo(function CaixaComanda() {
  const [comanda, setComanda] = useState('')
  const [validComanda, setValidComanda] = useState(0)
  const [isLoading, setIsLoading] = useState('')
  const [user, setUser] = useState({ credit: [], credit_usage: [], credit_charge: [] })
  function validateForm() {
    return comanda.length > 0
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setIsLoading(true)

    try {
      getCreditPerTag(comanda).then((res) => {
        if (res) {
          setUser(res)
          setValidComanda(2)
        } else {
          setValidComanda(1)
        }
      })

      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
    setIsLoading(false)
  }
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="comanda">
          <Form.Label> Comanda </Form.Label>{' '}
          <Form.Control autoFocus type="number" value={comanda} onChange={(e) => setComanda(e.target.value)} />{' '}
        </Form.Group>{' '}
        <LoaderButton
          block
          size="lg"
          className="my-2 w-100"
          type="submit"
          id="buscaComanda"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Procurar
        </LoaderButton>
      </Form>{' '}
      <div className="target" id="target" />
      <div id="target" />
      <hr />
      {user.transactions && user.transactions.length > 0 && validComanda === 2 && (
        <div id="comandaValida" className={user.remainingAmount >= 0 ? 'alert alert-success' : 'alert alert-danger'}>
          <h3>{user.userId.name}</h3>
          <p>{telefone(user.userId.phone)}</p>

          <hr />
          {getFeatures().includes('PrePaid') && (
            <>
              <AddingCredits dinheiro={dinheiro} getUserCredits={getUserCredits} user={user} />
              <RechargeHistory user={user} />
            </>
          )}

          {getFeatures().includes('PosPaid') && (
            <>
              <PayingCredits dinheiro={dinheiro} getUserCredits={getUserCredits} user={user} />
              <p className="text-center">
                <Link
                  to={`/user/${user.userId._id}/credito`}
                  className="btn btn-primary btn-lg"
                  id="btnAdicionarCreditos"
                >
                  Adicionar Créditos
                </Link>
              </p>
            </>
          )}
          <div>
            <ListaUserPedidos userId={user.userId._id} />
          </div>
        </div>
      )}
      {validComanda === 1 && (
        <div id="comandaInvalida" className="alert alert-warning">
          <h2>Comanda inválida</h2>
        </div>
      )}
    </div>
  )
})
