import React from 'react'
import PropTypes from 'prop-types'
import { timeSince } from '../lib/timeSince'
import api from '../services/api'

FilaDePedidos.propTypes = {
  pedidos: PropTypes.array
}

export default function FilaDePedidos({ pedidos, setPedidos }) {
  async function deliveryItem(itemId) {
    await api.put('deliveryQueue/deliver/' + itemId, {
      status: 'entregar'
    })

    setPedidos(pedidos.filter((p) => p._id !== itemId))
  }
  return (
    <div>
      {pedidos.map((v) => (
        <div key={v.id} className="card alert alert-warning" style={{ width: '17rem' }}>
          <div className="card-body">
            {v.tag ? (
              <h5 className="card-title">
                {v.userName} ({v.tag})
              </h5>
            ) : (
              <></>
            )}

            <p className="card-text">
              {v.productQuantity} - {v.productName}
            </p>
            <p className="card-text">{/* <b>{v.responsavel.split(' ')[0]}</b> */}</p>
            <p className="card-text">À {timeSince(new Date(v.created))}</p>
            <button onClick={() => deliveryItem(v._id)} className="btn btn-success">
              Concluir
            </button>
          </div>
        </div>
      ))}
    </div>
  )
}
