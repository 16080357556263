import React, { useState, useEffect, useRef, memo } from 'react'
import { getCurrentEvent, peopleInEvent, peopleOnQueue } from '../../services/events'
import Loading from '../Loading'
import { getUsersWithComanda } from '../../services/comanda'

export default memo(function EntryStatus() {
  const [inQueue, setInQueue] = useState(0)
  const [inEvent, setInEvent] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)
  const loadingRef = useRef(false)

  useEffect(() => {
    if (!loadingRef.current) {
      getEventInfo().then(() => setIsLoaded(true))
      loadingRef.current = true
    }
  }, [])

  async function getEventInfo() {
    const events = await getCurrentEvent()
    const users = await getUsersWithComanda()
    const res = await peopleOnQueue()
    setInQueue(res.length)
    setInEvent(users)
  }

  if (!isLoaded) {
    return <Loading size={40} />
  }

  return (
    <div className="my-4 alert alert-primary">
      <h3>{inEvent}</h3>
      <p>pessoas entraram</p>
      <p>
        Mas tem <span id="naFilaPortaria">{inQueue}</span> esperando pra passar pelo caixa
      </p>
    </div>
  )
})
