import React from 'react'
import PropTypes from 'prop-types'
import { dinheiro } from '../helpers/Formata'
import { getUserComanda } from '../helpers/Users'

ListItemsReport.propTypes = {
  items: PropTypes.array
}

export function ListItemsReport({ items }) {
  return (
    <div>
      <ul className="list-group">
        {items.map((e) => (
          <li className="list-group-item" key={e._id}>
            {e._id} | R$ {e.totalValue ? dinheiro(e.totalValue) : dinheiro(e.total)}
          </li>
        ))}
      </ul>
    </div>
  )
}

ListItemsReportUsers.propTypes = {
  items: PropTypes.array
}

export function ListItemsReportUsers({ items }) {
  return (
    <ul className="list-group list-group-flush">
      {items.map((e, k) => (
        <li className="list-group-item" key={e.id}>
          <a href={`usuario/${e.userId._id}`}>
            {' '}
            {k} | {e.userId.name} {e.tag}
          </a>
        </li>
      ))}
    </ul>
  )
}
