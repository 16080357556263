import React, { useState } from 'react'

import Acoes from '../components/Acoes'
import Comanda from '../components/Comanda'
import SelecionaBar from '../components/SelecionaBar'
import './Bar.css'
import Modal from '../components/Modal'
import { getFeatures, getBar, setBar } from '../services/auth'
import BarConsumer from './BarConsumir'
// import Loading from '../components/Loading'

export default function Bar() {
  const [modal, setModal] = useState({})
  const [comanda, setComanda] = useState('')
  const [user, setUser] = useState({})
  // const [loaded, setLoaded] = useState(false)

  function limpaBar() {
    setBar('')
    setModal({
      show: true,
      type: 'warning',
      title: 'Carregando',
      loading: true,
      msg: 'Aguarde...'
    })
    window.location = '/bar'
  }

  // if (!loaded) {
  //   return <Loading />
  // }

  function isBarOpened() {
    return getBar() === '' || JSON.stringify(getBar()) === 'null'
  }
  return (
    <>
      <div className="Bar">
        {user.tag ? (
          <BarConsumer user={user} setUser={setUser} />
        ) : (
          <>
            {!isBarOpened() ? (
              <span onClick={limpaBar} className="barLegenda">
                Bar: <b>{getBar()}</b> - Trocar
              </span>
            ) : (
              <></>
            )}
            <div className="lander">
              <Modal modal={modal} />
              <h1 id="nome">Bar</h1>

              <p className="text-muted"></p>
            </div>
            {isBarOpened() && getFeatures().includes('TwoBar') ? (
              <SelecionaBar />
            ) : (
              <div className="Comanda">
                <Comanda comanda={comanda} setComanda={setComanda} setUser={setUser} />
              </div>
            )}
            <div className="Historico">
              <Acoes />
            </div>
          </>
        )}
      </div>
    </>
  )
}
