import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import api from '../services/api'
import Modal from '../components/Modal'
import { getConsumptionById } from '../services/consumption'
import Loading from '../components/Loading'

export default function DesfazerVenda() {
  const params = useParams()
  const [modal, setModal] = useState({})
  const [consumption, setConsumption] = useState({})

  async function desfazer() {
    setModal({
      show: true,
      type: 'warning',
      title: 'Tentando desfazer a venda',
      msg: 'Aguarde...'
    })
    try {
      await api.post(`consumption/undo`, {
        consumptionId: params.creditUsageId
      })
      setModal({
        show: true,
        type: 'success',
        title: 'Sucesso',
        msg: 'Deu tudo certo!',
        link: '/bar'
      })
    } catch (e) {
      console.log(e.response)
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: JSON.stringify(e.message),
        link: '/'
      })
    }
  }

  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad() {
    try {
      getConsumptionById(params.creditUsageId).then((c) => {
        setConsumption(c)
      })
    } catch (e) {
      console.log(e.response)
    }
  }

  if (!consumption.products) {
    return <Loading />
  }
  let total = 0
  return (
    <div className="Venda">
      <Modal modal={modal} />
      <h1>Desfazendo venda</h1>
      <div className="lander">
        <p className="text-muted">Tem certeja que deseja cancelar esse produto?</p>
        <div id="target" className="text-center">
          <h2>Detalhes do Item</h2>
          {consumption.products.map((p) => {
            total += p.price * p.quantity
            return (
              <div key={p._id}>
                <h3>
                  {p.quantity} x {p.title}
                </h3>
                <p>R$ {p.price * p.quantity}</p>
              </div>
            )
          })}
          <h3>{consumption.title}</h3>
          <p>Quantidade: {consumption.quantity}</p>
          <h3>Valor: {total}</h3>
          <p>Lembre que o valor total será adicionado de volta a comanda</p>
          <h2>-- Comanda: {consumption.userTag} --</h2>
          <hr />
        </div>
      </div>
      <div className="text-center">
        <Link to="/bar">
          <button className="btn btn-success btn-lg bt mx-1">Voltar</button>
        </Link>
        |{' '}
        <button onClick={desfazer} className="btn btn-danger btn-lg bt">
          Sim
        </button>
      </div>
    </div>
  )
}
