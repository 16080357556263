import React, { useState, useEffect } from 'react'
import LoaderButton from '../components/LoaderButton'
import api from '../services/api'
import Modal from '../components/Modal'
import Loading from '../components/Loading'
import { getFreeCashRegister } from '../services/caixa'

export default function CaixaAbrir({ setIsCaixaOpen, setCaixa }) {
  const [caixas, setCaixas] = useState([])
  const [caixaNumber, setCaixaNumber] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [entrada, setEntrada] = useState(0)
  const [modal, setModal] = useState({})
  const [loaded, setIsLoaded] = useState(false)

  useEffect(() => {
    getFreeCashRegister().then((res) => {
      setCaixas(res.sort((a, b) => a.name - b.name))
      setIsLoaded(true)
    })
  }, [])

  async function handleSubmit() {
    setIsLoading(true)
    setModal({
      show: true,
      type: 'warning',
      title: 'Abrindo caixa',
      msg: 'Aguarde...',
      loading: true
    })
    try {
      const caixa = await api.post(`/cash-register/open`, {
        valorInicial: parseInt(entrada),
        name: caixaNumber
      })

      if (setIsCaixaOpen) {
        setIsCaixaOpen(true)
        setCaixa(caixa.data)
      } else {
        setModal({
          show: false,
          type: 'success',
          title: 'Sucesso',
          msg: 'Caixa aberto com sucesso',
          link: '/caixaOperar'
        })
        setCaixa(caixa.data)
      }
    } catch (e) {
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: JSON.stringify(e.response.data),
        link: '/'
      })
    }
    setIsLoading(false)
  }

  function validateForm() {
    return entrada.length > 0
  }
  if (!loaded) {
    return <Loading size={70} />
  }

  if (!caixas || caixas.length === 0) {
    return <>Treta em pegar os caixas</>
  }
  return (
    <div>
      <Modal modal={modal} />
      <h1>Abrir Caixa</h1>
      <p>Você não possui um caixa aberto ainda</p>
      <p>Selecione seu caixa</p>
      <select
        id="slcCaixa"
        onChange={(e) => setCaixaNumber(parseInt(e.target.value, 10))}
        className="form-control my-2"
        value={caixaNumber}
      >
        <option className="form-control" value="">
          --- Selecione ---
        </option>
        {caixas.map((c) => (
          <option key={c.name} value={c.name}>
            {c.name}
          </option>
        ))}
      </select>
      <label htmlFor="staticEmail" className="">
        Valor Inicial
      </label>
      <input onChange={(e) => setEntrada(e.target.value)} className="form-control " type="number" id="valorInicial" />
      <LoaderButton
        isLoading={isLoading}
        disabled={!validateForm()}
        onClick={handleSubmit}
        className="btn btn-primary my-2 w-100"
      >
        Abrir Caixa
      </LoaderButton>
    </div>
  )
}
