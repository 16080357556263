import api from './api'
import { randomCpf, randomEmail } from '../lib/faker'
import { getName, getClientId } from '../services/auth'

export async function findUserByText(text) {
  const response = await api.get('users/get/' + text)
  if (response.status === 200) {
    return response.data
  }
}

export async function getNumberOfPendingActions() {
  const response = await api.get('users/n/pendingActions')
  if (response.status === 200) {
    return response.data
  }
}

export async function isUserBlocked(query) {
  if (query === undefined) {
    return false
  }
  const user = await api.get('/users/get/' + query.replace(/[^a-zA-Z0-9]/g, ''))
  if (user.data) {
    const blocked = user.data.blocked.find((b) => b.client.id === getClientId() && b.isBlocked === true)
    return blocked !== undefined
  }
  return false
}

export async function isUserBlockedById(userId) {
  const user = await api.get('/users/blocked/' + userId)
  console.log(user)
  if (user.data) {
    return user.data
  }
  return false
}
export async function isUserBLockedByIdDetailed(userId) {
  const user = await api.get('/users/detailedBlock/' + userId)
  if (user.data) {
    return user.data
  }
  return false
}

export async function blockUserById(userId, data) {
  const user = await api.put('users/blocked/' + userId, data)
  return user
}

export async function unblockUserById(userId) {
  const user = await api.put('users/unblock/' + userId)
  return user
}

export async function addUserToQueue(data) {
  const user = await api.post('host', data)
  return user
}

export async function getUserBlocksById(userId) {
  const user = await api.get('users/blocks/' + userId)
  return user.data
}

// puta gambi, mas é por causa do input-mask supostamente
const convertDate = (inputDate) => {
  if (inputDate) {
    const parts = inputDate.split('/')
    const day = parts[0]
    const month = parts[1]
    const year = parts[2]
    return new Date(`${year}-${month}-${day}`)
  } else {
    return new Date()
  }
}

export async function createNewUser(userInfo) {
  return await api.post('users', userInfo)
}

export async function createUser(data) {
  const user = await api.post('users', data)
  return user
}

export async function updateUserById(data, id) {
  const user = await api.put('users/' + id, data)
  return user
}

export async function getUserById(id) {
  const user = await api.get('users/' + id)
  return user.data
}

export async function getNumberOfUsers() {
  const response = await api.get('users/count')
  if (response.status === 200) {
    return response.data
  }
}

export async function searchUserByText(text) {
  const response = await api.get('users/get/' + text)
  if (response.status === 200) {
    return response.data
  }
}

export async function deleteUserById(id) {
  const user = await api.delete('users/' + id)
  return user.data
}
