import React, { useEffect, useState } from 'react'

import './CaixaFila.css'
import { FiRefreshCw } from 'react-icons/fi'
import api from '../services/api'
import UsersQueue from './Caixa/UsersQueue'
import Loading from './Loading'

export default function CaixaFila() {
  const [portaria, setPortaria] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [portaria_raw, setPortaria_raw] = useState([])
  const [naKit, setNaKit] = useState(0)

  const [busca, setBusca] = useState('')

  useEffect(() => {
    onLoadPortaria()
    onLoadNaKit()
  }, [])

  async function onLoadPortaria() {
    setIsLoading(true)
    const res = await api.get('host')
    setPortaria(res.data)
    // setPortaria_raw(res.data.data)
    setIsLoading(false)
  }

  async function onLoadNaKit() {
    const user = await api.get('credit/usersCount')
    setNaKit(user.data)
  }

  // function search(terms, item) {
  //     return terms.every(function(term) {
  //         return ['name', 'lastname', 'tel'].some(function(attribute) {
  //             return item.Attributes[attribute].indexOf(term) !== -1;
  //         });
  //     });
  // }
  async function pesquisa() {
    setPortaria({
      portaria: portaria_raw.filter(
        (v) =>
          v.name.toLowerCase().includes(busca.toLocaleLowerCase()) ||
          v.lastname.toLowerCase().includes(busca.toLocaleLowerCase()) ||
          v.tel.includes(busca)
      )
    })
  }
  return (
    <div>
      <div className="my-4 alert alert-warning text-center">
        <h3 className="my-4">
          Na fila para entrar
          <span className="mx-2">
            <FiRefreshCw onClick={onLoadPortaria} />
          </span>
        </h3>

        <div className="">
          <input
            id="input-search"
            onChange={(e) => setBusca(e.target.value)}
            onKeyPress={pesquisa}
            className="form-control"
          />
          <hr />
          {isLoading ? (
            <Loading size={50} />
          ) : (
            <>
              <UsersQueue queue={portaria} />
            </>
          )}
        </div>
        <h1>
          <FiRefreshCw onClick={onLoadPortaria} />
        </h1>

        <div className="my-4 alert alert-primary text-center">
          <h3>{naKit}</h3>
          pessoas entraram
        </div>
      </div>
    </div>
  )
}
