import React, { useState, useEffect } from 'react'
import { useCart } from 'react-use-cart'
import { dinheiro } from '../helpers/Formata'
import { getBar } from './../services/auth'
import Cesta from '../containers/Cesta'

import './BarConsumir.css'
import 'react-tabs/style/react-tabs.css'

import { BsSearch } from 'react-icons/bs'
import api from '../services/api'
import LoaderButton from '../components/LoaderButton'

import { FcMinus } from 'react-icons/fc'
import { FiPlus } from 'react-icons/fi'
import { FaShoppingBasket } from 'react-icons/fa'

import ListaUserPedidos from './../components/ListaUserPedidos'

import { parseTelOrCpf } from '../helpers/Users'
import { getFav, setFav } from '../services/produtos'

import { hasSaldo, tryToAddItem } from '../helpers/Bar'
import FavoritoProduto from '../components/FavoritoProduto'
import ProductModal from '../components/Bar/ProductModal'
import { getUserPromotions } from '../services/promotions'

export default function BarConsumer({ user, setUser }) {
  const [produtos, setProdutos] = useState([])
  const { items, updateItemQuantity, addItem, totalItems, emptyCart } = useCart()
  const [isL, setIsL] = useState(false)
  const [search, setSearch] = useState('')
  const [savingPreferences, setSavingPreferences] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isSearchLoaded, setIsSearchLoaded] = useState(false)
  const [favoritos, setFavoritos] = useState([])
  const [promos, setPromos] = useState([])
  const products = [
    { id: 1, name: 'Produto 1' },
    { id: 2, name: 'Produto 2' },
    { id: 3, name: 'Produto 3' }
  ]

  function addPromoItem(item) {
    const itemInCart = items.find((v) => v.id === item.id)
    if (itemInCart !== undefined) {
      if (item.maxValue > itemInCart.quantity) {
        addItem(item)
      }
    } else {
      addItem(item)
    }
  }

  useEffect(() => {
    onLoad()
  }, [search])

  useEffect(() => {
    buscaDestaques()
  }, [])

  async function onLoad() {
    if (search !== '') {
      buscaProdutos()
    } else {
      buscaDestaques()
    }
    const userPromo = await getUserPromotions(user.userId._id)
    setPromos(userPromo)
  }

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  function limpar() {
    document.getElementById('bar-search').value = ''
    setProdutos([])
  }
  async function buscaProdutos() {
    if (search.length > 1) {
      setIsL(true)
      setIsSearchLoaded(false)
      const res = await api.get('/product/search/' + String(search))
      setIsL(false)
      setIsSearchLoaded(true)
      setProdutos(res.data)
    }
  }

  function updateItemQuantityRagazzid(item, valor) {
    const cartItem = items.find((v) => v.id === item)
    if (valor <= cartItem.maxValue) {
      updateItemQuantity(item, valor)
    }
  }

  async function buscaDestaques() {
    const fav = await getFav()
    if (fav.length > 0) {
      setProdutos(fav)
      setFavoritos(fav)
    } else {
      setIsL(true)
      //const res = await api.get('/product/spotlight')
      //console.log(res.data)
      setIsL(false)
      //setProdutos(res.data)
    }
    setIsSearchLoaded(true)
  }

  async function saveFavoritos() {
    if (getFav() !== null && typeof getFav() === 'object' && getFav().items && getFav().items.length > 0) {
      setSavingPreferences(true)
      try {
        await api.post('/product/favorites', {
          items: JSON.stringify(getFav().items)
        })
        setSavingPreferences(false)
      } catch (e) {
        console.log(e)
        setSavingPreferences(false)
      }
    }
  }

  async function clearFavoritos() {
    const ctz = window.confirm('Tem certeza que quer limpar seus favoritos?')
    if (ctz) {
      setSavingPreferences(true)
      const fav = { items: [] }
      setFav(fav)
      await api.post('/product/favorites', {
        items: JSON.stringify(getFav().items)
      })
      setProdutos([])
      setSavingPreferences(false)
    }
  }

  return (
    <div id="topo" className="">
      {user.tag ? (
        <div className="text-center">
          <h4>
            {' '}
            {user.userId.name} - {parseTelOrCpf(user.userId)} <br />
          </h4>
          <h2>Comanda: {user.tag}</h2>
          <p>
            {' '}
            <b
              style={{
                color: user.remainingAmount > 15 ? '#116e01' : 'red',
                'font-size': '30px'
              }}
            >
              {' '}
              R$ {user.remainingAmount}
            </b>
          </p>
          <button
            className="btn btn-sm btn-warning"
            onClick={() => {
              emptyCart()
              setUser({})
            }}
          >
            Voltar
          </button>

          {promos.length > 0 && <p>Promos:</p>}
          <div id="promoItems">
            {promos.map((p) => {
              p.id = p._id
              p.itemId = p.productId
              p.promoId = p._id
              p.image = 'https://w7.pngwing.com/pngs/146/209/png-transparent-promos.png'
              p.title = p.promotionName
              p.itemTotal = 0
              p.price = 0
              p.promo = true
              p.barName = getBar() ? getBar : 'Entrada'
              p.maxValue = Math.floor(p.count / p.quantityRequired)

              return (
                <div className="col-6 box text-center my-4" key={p.id}>
                  <p className=""> {p.promotionName}</p>
                  {Math.floor(p.count / p.quantityRequired) ? (
                    <div className="image">
                      <img
                        id={p.promotionName}
                        className="box img-fluid"
                        onClick={() => {
                          addPromoItem(p)
                        }}
                        src="https://w7.pngwing.com/pngs/146/209/png-transparent-promos.png"
                        alt={`promo-${p.item}`}
                      ></img>
                      {items.find((v) => v.id === p.id) ? (
                        <span className="numberOfItems">{items.find((v) => v.id === p.id).quantity}</span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  ) : (
                    <>Falta: {p.quantityRequired - p.count}</>
                  )}

                  <p className="text-center">{Math.floor(p.count / p.quantityRequired)} </p>
                  {items.find((v) => v.id === p.id) ? (
                    <>
                      <button
                        className="m-2 btn btn-light btn-success"
                        onClick={() =>
                          updateItemQuantityRagazzid(
                            items.find((v) => v.id === p.id).id,
                            items.find((v) => v.id === p.id).quantity - 1
                          )
                        }
                      >
                        <FcMinus />
                      </button>
                      <button
                        className="m-2 btn btn-success"
                        onClick={() =>
                          updateItemQuantityRagazzid(
                            items.find((v) => v.id === p.id).id,
                            items.find((v) => v.id === p.id).quantity + 1
                          )
                        }
                      >
                        <FiPlus />
                      </button>
                    </>
                  ) : (
                    <span></span>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <>Loading...</>
      )}
      <hr />

      <h3>Pesquise pelo Produto</h3>
      <small>
        Digite pelo menos <b>duas letras</b> para aparecer os produtos
      </small>
      <div className="container">
        <div className="row">
          <div className="container">
            <div className="my-3 input-group">
              <div id="search-autocomplete" className="form-outline">
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  type="search"
                  id="bar-search"
                  className="form-control"
                ></input>
              </div>

              <LoaderButton
                isLoading={isL}
                onClick={buscaProdutos}
                type="button"
                className="rounded mx-2 my-2 btn btn-primary"
              >
                <BsSearch />
              </LoaderButton>

              <button onClick={limpar} className="my-2 btn roundedfirst btn-primary">
                Limpar
              </button>

              <div id="" className="col-12 text-center">
                <span onClick={handleOpenModal}>
                  {' '}
                  <h4>
                    <FaShoppingBasket />
                    <small className="color-red">
                      {' '}
                      <b>({totalItems})</b>{' '}
                    </small>
                  </h4>
                </span>
              </div>
            </div>
          </div>
          <ProductModal
            show={showModal}
            handleClose={handleCloseModal}
            products={products}
            user={user}
            setUser={setUser}
          />
          <div className="container">
            <hr />

            <div className="row" id="busca-alvo">
              {produtos.length > 0 ? (
                produtos.map((p) => (
                  <div className="col-6 box text-center my-4" key={p.id}>
                    <p className=""> {p.title}</p>
                    <div className="image">
                      <img
                        className={hasSaldo(p, user, totalItems, items) ? 'box img-fluid ' : 'grayScale box img-fluid'}
                        onClick={() => {
                          tryToAddItem(p, addItem, user, totalItems, items)
                        }}
                        src={p.image}
                        alt={p.title}
                      ></img>
                      {items.find((v) => v.id === p.id) ? (
                        <span className="numberOfItems">{items.find((v) => v.id === p.id).quantity}</span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <p className="text-center"> R$ {p.price.toFixed(2)}</p>
                    <p className="text-center">
                      <FavoritoProduto produto={p} favoritos={favoritos} setFavoritos={setFavoritos} />
                    </p>
                    {items.find((v) => v.id === p.id) ? (
                      <>
                        <button
                          className="m-2 btn btn-light btn-success"
                          onClick={() =>
                            updateItemQuantity(
                              items.find((v) => v.id === p.id).id,
                              items.find((v) => v.id === p.id).quantity - 1
                            )
                          }
                        >
                          <FcMinus />
                        </button>
                        <button
                          className="m-2 btn btn-success"
                          onClick={() =>
                            hasSaldo(p, user, totalItems, items)
                              ? updateItemQuantity(
                                  items.find((v) => v.id === p.id).id,
                                  items.find((v) => v.id === p.id).quantity + 1
                                )
                              : null
                          }
                        >
                          <FiPlus />
                        </button>
                      </>
                    ) : (
                      <span></span>
                    )}
                  </div>
                ))
              ) : (
                <span>
                  {!isSearchLoaded ? (
                    <img src="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif" alt="Loading" />
                  ) : (
                    <div className="row">
                      {search.length > 1 && (
                        <div className="text-center">
                          Busca sem resultados, por favor pesquise um produto <hr />
                        </div>
                      )}
                    </div>
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
        <div id="cestaKitnet" className="col-12 text-center">
          {user.tag && <Cesta user={user} setUser={setUser} />}
        </div>
        <hr />
        <h2>Histórico</h2>
        {user.tag && <ListaUserPedidos userId={user.userId._id} />}
      </div>
      <div className="text-center">
        <hr />
        <LoaderButton isLoading={savingPreferences} className="btn btn-success" onClick={saveFavoritos}>
          Salvar Preferências
        </LoaderButton>
        |
        <LoaderButton isLoading={savingPreferences} className="btn btn-danger" onClick={clearFavoritos}>
          Limpar Favoritos
        </LoaderButton>
      </div>
    </div>
  )
}
