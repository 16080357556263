import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import api from '../services/api'

import Modal from '../components/Modal'
import Loading from '../components/Loading'

export default function CaixaDesfazer() {
  const params = useParams()

  const [data, setData] = useState({})
  const [modal, setModal] = useState({})

  const [transaction, setTransaction] = useState({})
  const [user, setUser] = useState({})
  const [tag, setTag] = useState('')
  const [creditId, setCreditId] = useState('')

  useEffect(() => {
    onLoad()
  }, [])

  async function voltaCredito(cashTransactionId) {
    const formData = {
      creditId,
      cashTransactionId: params.cashTransactionId
    }

    setModal({
      show: true,
      type: 'warning',
      title: 'Aguarde...',
      loading: true,
      msg: 'Tentando desfazer a venda'
    })

    try {
      await api.post('cash-register/undoTransactionAndCredit', formData)
      setModal({
        show: true,
        type: 'success',
        title: 'Sucesso',
        msg: 'Desfeito com sucesso!',
        link: '/caixaOperar'
      })
    } catch (e) {
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: JSON.stringify(e.data.message),
        link: '/'
      })
    }
  }

  async function onLoad() {
    try {
      const r = await api.get(`credit/user/${params.id}`)
      if (r.data.userId) {
        setUser(r.data.userId)
        setTransaction(r.data.transactions.find((t) => t._id === params.credito_id))
        setTag(r.data.tag)
        setCreditId(r.data._id)
        if (transaction.isReversed) {
          alert('Já foi desfeito :(')
          window.location = '/caixa'
        }
      }
    } catch (e) {
      if (e.response.status === 401) {
        alert('Você não tem permissão pra isso')
        window.location = '/caixa'
      }
      console.log(e)
      alert(e.response)
    }
  }

  function vorta() {
    window.history.back()
  }
  console.log(transaction)
  return (
    <div>
      <Modal modal={modal} />
      <h1>Tem certeza que deseja desfazer o crédito?</h1>
      <div className="text-center">
        {Number.isInteger(transaction.amount) ? (
          <>
            <h2>Informações</h2>
            Crédito adicionado por:
            <p>
              <br></br> <b>{transaction.adminName}</b> @ {new Date(transaction.createdAt).toLocaleString('pt-BR')}
            </p>
            <h3> Comanda</h3>
            <h2> {tag}</h2>
            <div>
              <h1>R$ {transaction.amount}</h1>
              <button className="btn btn-success my-3 mx-3" onClick={vorta}>
                {' '}
                Voltar
              </button>
              <button id="desfazerVenda" className="btn btn-danger my-3" onClick={() => voltaCredito(transaction._id)}>
                {' '}
                Sim
              </button>
            </div>
          </>
        ) : (
          <Loading size={60} />
        )}
      </div>
    </div>
  )
}
