import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Modal, Button } from 'react-bootstrap'
import { blockUserById, isUserBlockedById, unblockUserById } from '../../services/users'
const BlockUserModal = ({ isOpen, onClose, userId }) => {
  const [blockDate, setBlockDate] = useState(new Date())
  const [blockedObj, setBlockedObj] = useState({})
  const [reason, setReason] = useState('')
  const [blocked, setBlocked] = useState(false)

  const handleBlock = () => {
    // Add your block logic here
    blockUserById(userId, { blockedUntil: blockDate, reason }).then(() => {
      onClose(false)
    })
  }

  const handleUnblock = () => {
    // Add your unblock logic here
    unblockUserById(userId).then(() => {
      onClose(false)
    })
  }

  useEffect(() => {
    isUserBlockedById(userId).then((data) => {
      if (data.reason) {
        setBlocked(true)
        setBlockedObj(data)
      } else {
        setBlocked(false)
      }
    })
  }, [userId])

  function isBlockValid() {
    return blockDate && reason
  }
  return (
    <Modal show={isOpen} onHide={() => onClose(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Bloqueio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {blocked ? (
          <div>
            <p>Foi bloqueado em {new Date(blockedObj.createdAt).toLocaleString('pt-BR')}</p>
            <p>Motivo</p>
            <p className="alert alert-warning">{blockedObj.reason}</p>
          </div>
        ) : (
          <div>
            <p>
              <label>Motivo</label>
              <input
                type="text"
                className="form form-control my-2 p-2"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </p>
            <p>
              <label>Até</label>
              <p>
                <DatePicker
                  selected={blockDate}
                  className="form form-control my-2 p-2"
                  onChange={(date) => setBlockDate(date)}
                />
              </p>
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {blocked ? (
          <Button variant="warning" onClick={handleUnblock}>
            Desbloquear
          </Button>
        ) : (
          <Button variant="danger" onClick={handleBlock} disabled={!isBlockValid()}>
            Bloquear
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default BlockUserModal
