import { useEffect, useState } from 'react'
import { getLastRegisterTransactionsById } from '../../services/caixa'
import { totalCard, totalCash, getExit } from '../../helpers/Numbers'
export default function CaixaTransactions({ caixaId, initialCashAmount }) {
  const [transactions, setTransactions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getLastRegisterTransactionsById(caixaId).then((data) => {
      if (data) {
        setTransactions(data)
      } else {
        setTransactions([])
      }
      setLoading(false)
    })
  }, [caixaId])

  if (loading) return <div>Carregando</div>
  return (
    <div>
      <div className="row my-2">
        <div className="col">Dinheiro Espécie</div>
        <div className="col" id="dinheiro">
          R$ {totalCash(transactions) + initialCashAmount}
          ,00
        </div>
      </div>
      <div className="row my-2">
        <div className="col">Dinheiro Cartão</div>
        <div className="col" id="cartao">
          R$ {totalCard(transactions)}
          ,00
        </div>
      </div>
      <div className="row my-2">
        <div className="col">Total Sangria</div>
        <div className="col" id="sangria">
          R$ {getExit(transactions) * -1}
          ,00
        </div>
      </div>
    </div>
  )
}
