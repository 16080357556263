import React, { useEffect, useState } from 'react'
import { getCreditPerUserId } from '../../services/credit'
import { formatFutureDuration } from '../../helpers/Datas'

export default function UserCreditUsage({ userId }) {
  const [userCredit, setUserCredit] = useState({ transactions: [] })
  useEffect(() => {
    getCreditPerUserId(userId).then((e) => {
      setUserCredit(e)
    })
  }, [userId])

  if (!userCredit) {
    return <h4>Usuário ainda não adicionou créditos</h4>
  }
  return (
    <div className="my-5">
      <h3>Créditos & Consumos</h3>

      <div>
        {userCredit.tag !== '' ? (
          <span>Tag atual {userCredit.tag}</span>
        ) : (
          <span>Usuário não está associado a uma comanda no momento</span>
        )}
      </div>
      <div>
        {userCredit.transactions.length > 0 ? (
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Data</th>
                <th>Descrição</th>
                <th>Comanda</th>
                <th>Validade</th>
                <th>Restante</th>
                <th>Consumido</th>
                <th>Operador</th>
              </tr>
            </thead>
            <tbody>
              {userCredit.transactions.map((t) => (
                <tr key={t._id}>
                  <td>{new Date(t.createdAt).toLocaleString('pt-Br')}</td>
                  <td>{t.via}</td>
                  <td>{t.tag}</td>
                  <td>{t.amount > 0 ? formatFutureDuration(new Date(t.expiresAt)) : ''}</td>
                  <td>{t.remainingAmount}</td>
                  <td>{t.amount}</td>
                  <td>{t.adminName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <span>Usuário ainda não tem histórico de transações</span>
        )}
      </div>
    </div>
  )
}
