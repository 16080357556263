import api from './api'

export async function getLoggedAdminInfo() {
  const res = await api.get('admin/me')
  if (res.status === 200) {
    return res.data
  } else {
    return false
  }
}

export async function createAdmin(data) {
  const res = await api.post('admin', data)
  if (res.status === 201) {
    return res.data
  }
}

export async function getAdminById(id) {
  const res = await api.get(`admin/${id}`)
  return res.data
}

export async function updateAdmin(data, adminId) {
  const res = await api.put(`admin/${adminId}`, data)
  if (res.status === 200) {
    return res.data
  }
}

export async function deleteAdminById(id) {
  const user = await api.delete('admin/' + id)
  return user.data
} 
