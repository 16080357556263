export function formatToBRL(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}
export function formatToBRLWithSymbol(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}
export function formatToBRLWithoutSymbol(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
    .format(value)
    .replace('R$', '')
    .trim()
}

export function totalCard(t) {
  if (t.card === undefined && t.length > 0) {
    return t.filter((trans) => trans.type === 'CARD' && trans.amount > 0).reduce((acc, curr) => acc + curr.amount, 0)
  }
  const balance = t.card + t.creditCard + t.debitCard
  return balance ? balance : 0
}

export function totalCash(t) {
  if (t === undefined) {
    return 0
  }
  if (t.card === undefined) {
    const a = t.filter((trans) => trans.type === 'CASH' && !trans.depletion).reduce((acc, curr) => acc + curr.amount, 0)
    const b = t.filter((trans) => trans.type === 'CASH' && trans.depletion).reduce((acc, curr) => acc + curr.amount, 0)
    console.log([a, b])
    console.log(t)
    return a + b
  }
  return 2
}

export function getEntry(t) {
  if (t === undefined) {
    return 0
  }
  let total = 0
  for (const v of t) {
    if (v.amount > 0 && v.de) {
      total += v.amount
    }
  }
  return total
}
export function getExit(t) {
  if (t === undefined) {
    return 0
  }
  let total = 0
  for (const v of t) {
    if (v.amount < 0 && !v.depletion) {
      total += v.amount
    }
  }
  return total
}
