import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../services/api";
import { useParams } from "react-router-dom";

const PromocaoNova = () => {
  const [name, setName] = useState("");
  const [item, setItem] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [quantityFree, setQuantityFree] = useState(0);
  const [cashback, setCashback] = useState(0);
  const [inicio, setInicio] = useState(new Date());
  const [fim, setFim] = useState(new Date());
  const [promotionType, setPromotionType] = useState("");
  const params = useParams();
  useEffect(() => {
    getItem();
  }, []);

  const getItem = async () => {
    try {
      const response = await api.get(`product/${params.id}`);
      setItem(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name,
      item,
      quantity,
      quantityFree,
      inicio,
      fim,
      cashback,
      promotionType
    };
    try {
      await api.post("promotion", formData);
      alert("Criada com sucesso");
      window.location = "/promocoes";
    } catch (error) {
      console.error(error.response);
    }
  };

  return (
    <div>
      <h1>Promoção para</h1>
      <div>
        <h3>{item.title}</h3>
        Valor unitário: <b>R$ {item.price}</b>
      </div>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nome da Promoção</label>
          <input
            type="text"
            className="form-control my-2"
            id="promo_nome"
            onChange={(e) => setName(e.target.value)}
          ></input>
        </div>
        <div className="form-group">
          <label>Quantas compras para acionar promoção?</label>
          <input
            type="number"
            className="form-control my-2"
            id="promo_quantity"
            onChange={(e) => setQuantity(e.target.value)}
          ></input>
        </div>
        <div className="form-group">
          <label>Promoção válida a partir de:</label>
          <p>
          <DatePicker
            id="promoção_a_partir"
            selected={inicio}
            onChange={(date) => setInicio(date)}
            timeInputLabel="Horário:"
            dateFormat="dd/MM/yyyy h:mm aa"
            showTimeInput
            className="form form-control my-2 mx-2"
          />
          </p>
        </div>
        <div className="form-group">
          <label>Promoção válida até:</label>
          <p>
          <DatePicker
            id="promoção_valida_até"
            selected={fim}
            onChange={(date) => setFim(date)}
            timeInputLabel="Horário:"
            dateFormat="dd/MM/yyyy h:mm aa"
            showTimeInput
            className="form form-control my-2 mx-2"
          />
          </p>
        </div>
        <div className="container mt-2">
      <p className="my-2">Tipo de promoção</p>
      <div className="list-group">
        <label className="list-group-item d-flex align-items-center">
          <input 
            type="radio" 
            name="tipoPromocao" 
            value="cashback" 
            className="form-check-input me-2"
            onClick={() => setPromotionType("cashback")}
          />
          Cash Back (Adiciona crédito ao usuário)
        </label>
        <label className="list-group-item d-flex align-items-center">
          <input 
            type="radio" 
            name="tipoPromocao" 
            value="produtoGratis" 
            className="form-check-input me-2"
            onClick={() => setPromotionType("freeProduct")}
          />
          Produto Grátis (Gera um item promo para o item dessa promoção)
        </label>
      </div>
    </div>
    <div className="my-2">
      {promotionType === "cashback" && (
        <div className="form-group">
        <label>Adicionar quantos créditos ao usuário?</label>
        <input
          type="number"
          className="form-control"
          id="promo_valor"
          onChange={(e) => setCashback(e.target.value)}
        ></input>
      </div>
      )}
      {promotionType === "freeProduct" && (
        <div className="form-group">
        <label>Receber quantos produtos grátis?</label>
        <input
          type="number"
          className="form-control"
          id="promo_valor"
          onChange={(e) => setQuantityFree(e.target.value)}
        ></input>
      </div>
      )}
    
        
    </div>
        <button type="submit" className="my-4 btn btn-primary">
          Criar
        </button>
      </form>
    </div>
  );
};

PromocaoNova.propTypes = {
  match: PropTypes.object,
};

export default PromocaoNova;
