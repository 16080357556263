import React from 'react'
import { Link } from 'react-router-dom'
import { getFeatures, getRole } from '../services/auth'

export default function PortariaLinks() {
  return (
    getFeatures().includes('Gate') && (
      <div className="buttons-container">
        {JSON.stringify()}
        {getRole().includes('Host') && (
          <p>
            <Link to="/portaria" className="">
              <button className="button btn btn-warning ">
                <span className="button-font-size">Portaria</span>
              </button>
            </Link>
          </p>
        )}
        {getFeatures().includes('PosPago') && (
          <>
            <p>
              <Link to="/entrada" className="">
                <button className="button btn btn-primary ">
                  <span className="button-font-size">Entrada</span>
                </button>
              </Link>
            </p>
            <p>
              <Link to="/saida" className="">
                <button className="button btn btn-warning ">
                  <span className="button-font-size">Saída</span>
                </button>
              </Link>
            </p>
          </>
        )}

        {getFeatures().includes('Sympla') && (
          <>
            <p>
              <Link to="/portariaSympla" className="">
                <button className="button btn btn-primary ">
                  <span className="button-font-size">Sympla</span>
                </button>
              </Link>
            </p>
            <p>
              <Link to="/PessoasEvento" className="">
                <button className="button btn btn-info">
                  <span className="button-font-size">Adicionar pessoas ao evento</span>
                </button>
              </Link>
            </p>
          </>
        )}
        <hr />
      </div>
    )
  )
}
