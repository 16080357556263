import api from './api'

export async function peopleInEvent(eventId) {
  const response = await api.get('users/event/' + eventId)
  if (response.status === 200) {
    return response.data
  }
}

export async function peopleOnQueue() {
  const response = await api.get('host')
  if (response.status === 200) {
    return response.data
  }
}

export async function getCurrentEvent() {
  const response = await api.get('events/now')
  if (response.status === 200) {
    return response.data
  }
}

export async function isOkToAddPeople() {
  const pInEvent = await peopleInEvent()
  const event = await getCurrentEvent()
  if (event.length > 0) {
    if (event[0].limite_pessoas >= pInEvent.data.length) {
      return true
    }
  }
  return false
}

export async function addUserToEvent(userId) {
  try {
    const res = await api.post('events/user/' + userId)
    return res.data
  } catch (e) {
    console.log(e)
    return false
  }
}

export async function createEvent(formData) {
  const res = await api.post('events', formData)
  return res.data
}

export async function checkEventInTimeRange(inicio, fim) {
  try {
    const response = await api.get(`events/check/${inicio}/${fim}`)
    if (response.status === 200) {
      if (response.data.length > 0) {
        return response.data
      } else {
        return false
      }
    }
  } catch (e) {
    console.log(e)
    return false
  }
}
