import React, { useEffect, useState, memo } from 'react'
import api from '../services/api'

export default memo(function CaixaAtividades() {
  const [atividades, setAtividades] = useState([])

  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad() {
    const atividades = await api.get('feed/me')
    setAtividades(atividades.data)
  }
  return (
    <div>
      <div className="my-4 alert alert-success">
        <h2>Minhas Atividades</h2>
        <ul className="list-group">
          {atividades.map((u) => (
            <li key={u._id} className="list-group-item">
              {' '}
              {new Date(u.createdAt).toLocaleString('pt-BR')} | {u.description}
            </li>
          ))}
        </ul>
        <div className="my-2">
          <p className="text-center">
            <a href="/minhasAtividades">Ver minhas atividades</a>
          </p>
        </div>
      </div>
    </div>
  )
})
