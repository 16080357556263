import api from './api'

export default async function getUserByComanda(comanda) {
  const res = await api.get(`credit/user/tag/${comanda}`)
  return res.data
}

export async function getUserComandaById(id) {
  const res = await api.get(`credit/user/${id}`)
  return res.data
}

export async function getUsersWithComanda() {
  try {
    const res = await api.get('credit/usersCount')
    if (res.data) {
      return res.data
    } else {
      return 0
    }
  } catch (e) {
    return 0
  }
}
