import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom'

import 'react-datepicker/dist/react-datepicker.css';
import api from '../services/api';

const PromocaoEdita = () => {
  const [name, setName] = useState('');
  const [item, setItem] = useState({});
  const [promo, setPromo] = useState({ productId: {}});
  const [quantidade, setQuantidade] = useState(0);
  const [inicio, setInicio] = useState(new Date());
  const [fim, setFim] = useState(new Date());
  const [valor, setValor] = useState(0);
  const params = useParams()
  useEffect(() => {
    getPromo();
  }, []);

  const getPromo = async () => {
    const { id } = params
    const promo = await api.get('promotion/' + id);
    setPromo(promo.data);
    setName(promo.data.name);
    setQuantidade(promo.data.quantityRequired);
    setInicio(new Date(promo.data.validFrom));
    setFim(new Date(promo.data.validUntil));
    setValor(0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: name,
      quantityRequired: quantidade,
      validFrom: new Date(inicio),
      validUntil: new Date(fim),
    };
    try {
      const { id } = params;
      await api.put('promotion/' + id, formData);
      alert('Atualizado com sucesso');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <h1>Editando Promocao {name}</h1>
      <div>
        <h3>{promo.productId.title}</h3>
        Valor unitário: <b>R$ {promo.productId.price}</b>
      </div>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nome da Promoção</label>
          <input
            type="text"
            value={name}
            className="form-control"
            id="promo_nome"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        { promo.type === 'freeProduct' && <div className="form-group">
          <label>Quantas compras para acionar promoção?</label>
          <input
            type="number"
            value={quantidade}
            className="form-control"
            id="promo_quantidade"
            onChange={(e) => setQuantidade(e.target.value)}
          />
        </div>}
        
        { promo.type === 'cashback' && <div className="form-group">
          <label>Adicionar quantos créditos ao usuário?</label>
          <input
            type="number"
            value={valor}
            className="form-control"
            id="promo_valor"
            onChange={(e) => setValor(e.target.value)}
          />
        </div>}
        
        <div className="form-group">
          <label>Promoção válida a partir de:</label>
          <p><DatePicker
            id="promoção_a_partir"
            selected={inicio}
            onChange={(date) => setInicio(date)}
            timeInputLabel="Horário:"
            dateFormat="dd/MM/yyyy h:mm aa"
            showTimeInput
            className='form form-control'
          />
          </p>
        </div>
        <div className="form-group">
          <label>Promoção válida até:</label>
          <p><DatePicker
            id="promoção_valida_até"
            selected={fim}
            onChange={(date) => setFim(date)}
            timeInputLabel="Horário:"
            dateFormat="dd/MM/yyyy h:mm aa"
            showTimeInput
            className='form form-control'
          />
          </p>
        </div>
        <button type="submit" onClick={handleSubmit} className="btn btn-primary">
          Atualizar
        </button>
      </form>
    </div>
  );
};

export default PromocaoEdita;
