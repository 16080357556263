import React, { useState } from 'react'
import { useAppContext } from '../lib/contextLib'
import Form from 'react-bootstrap/Form'
import LoaderButton from '../components/LoaderButton'
// import { onError } from "../lib/errorLib";
import api from '../services/api'
import { login, setClientId, setClientName, setRole, setFeatures, v2Login } from '../services/auth'

import './Login.css'

export default function Login() {
  const [isLoading, setIsLoading] = useState(false)
  const { userHasAuthenticated } = useAppContext()
  const [tel, setTel] = useState('')
  const [password, setPassword] = useState('')

  function validateForm() {
    return tel.length > 0
  }
  async function handleSubmit(event) {
    event.preventDefault()
    setIsLoading(true)

    try {
      if (process.env.REACT_APP_KITNET_V2 === 'true') {
        const res = await v2Login(tel.replace(/[^a-zA-Z0-9]/g, ''), password)
        if (res) {
          login(res.token, '', 'tmp', JSON.stringify(['Operator']), 'someId')
        } else {
          console.log('falhou')
          // alert('Senha de acesso incorreta')
        }
      } else {
        const res = await api.post('/users/authenticate', {
          tel: tel.replace(/[^a-zA-Z0-9]/g, ''),
          pass: password
        })
        const data = res.data.data
        const rt = res.data.rt
        if (data.client.length === 1) {
          login(data.jwtToken, rt, data.name + ' ' + data.lastname, data.client[0].role, data.id)
          const res = await api.post('users/select/client', {
            clientId: data.client[0].id
          })
          setFeatures(res.data.clientModules.join(','))
          setClientId(data.client[0].id)
          setClientName(data.client[0].name)
          setRole(data.client[0].role)
        } else {
          login(data.jwtToken, rt, data.name + ' ' + data.lastname, data.role, data.id)
        }
      }

      userHasAuthenticated(true)
      window.location = '/'
      setIsLoading(false)
    } catch (e) {
      alert('Senha de acesso incorreta')
      console.log(e)
      setIsLoading(false)
    }
  }

  return (
    <div className="Login">
      <div className="loginImg my-4">{/* <img src="logo.png" alt="logoKitnet" width="40%"/> */}</div>
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg">
          <Form.Label>Telefone </Form.Label> <small> Apenas numeros (19999999999)</small>
          <Form.Control
            autoFocus
            id="tel"
            maxLength="11"
            type="string"
            value={tel}
            onChange={(e) => setTel(e.target.value.replace(/\D/g, ''))}
          />
        </Form.Group>

        <Form.Group size="lg">
          <Form.Label>Senha </Form.Label> <small></small>
          <Form.Control id="password" type="password" onChange={(e) => setPassword(e.target.value)} />
        </Form.Group>

        <div className="d-grid gap-2 my-4">
          <LoaderButton block className="btn btn-lg" type="submit" isLoading={isLoading} disabled={!validateForm()}>
            Login
          </LoaderButton>
        </div>
      </Form>
    </div>
  )
}
