import api from './api'

export async function getHostById(id) {
  const host = await api.get('host/' + id)
  return host.data
}

export async function lockHost(id) {
  const host = await api.put('host/lock/' + id)
  return host.data
}

export async function unlockHost(id) {
  const host = await api.put('host/unlock/' + id)
  return host.data
}

export async function removeFromHost(id) {
  const host = await api.delete('host/' + id)
  return host.data
}
