import React, { useEffect, useState } from 'react'

import './Bar.css'
import Loading from '../components/Loading'
import CaixaAbrir from './CaixaAbrir'
import AutoComplete from '../components/AutoComplete'
import BasicFields from '../components/Entrance/BasicFields'
import getUserByComanda from '../services/comanda'
import { createNewUser } from '../services/users'
import LoaderButton from '../components/LoaderButton'
import { Modal, Button } from 'react-bootstrap'
import { getCurrentEvent } from '../services/events'
import { getUserTagById } from '../services/credit'
import { transactionAndCredit } from '../services/caixa'
import { isCaixaOpen as ico } from '../services/caixa'

export default function Entrada() {
  const [isFetchingEvents, setIsFecthingEvents] = useState(true)
  const [ev, setEv] = useState({})

  const [showModal, setShowModal] = useState(false)
  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true)
  const [modalTitle, setModalTitle] = useState('')
  const [modalText, setModalText] = useState('')

  const [isLoading, setIsLoading] = useState(true)
  const [isCreating, setIsCreating] = useState(false)
  const [isAssociating, setIsAssociating] = useState(false)
  const [createStatus, setCreateStatus] = useState({})
  const [isCaixaOpen, setIsCaixaOpen] = useState(false)
  const [queryString, setQueryString] = useState('')
  const [userFound, setUserFound] = useState({})
  const [tagFound, setTagFound] = useState(0)
  const [userWithTag, setUserWithTag] = useState(0)
  const [isFilled, setIsFilled] = useState(false)

  const [doc, setDoc] = useState('')
  const [gringo, setGringo] = useState(false)
  const [name, setName] = useState('')
  const [tel, setTel] = useState('')
  const [tag, setTag] = useState(0)
  const [email, setEmail] = useState('')
  const [bd, setBd] = useState('')
  const [entry, setEntry] = useState(0)
  const [credit, setCredit] = useState(0)
  const [payment, setPayment] = useState('')

  useEffect(() => {
    getCurrentEvent().then((e) => {
      if (e.length && e.length > 0) {
        setEv(e[0])
      }
      setIsFecthingEvents(false)
    })
  }, [])

  useEffect(() => {
    if (userFound.doc) {
      setTel(userFound.phone)
      setDoc(userFound.doc)
      setName(userFound.name)
      setEmail(userFound.email)
      setBd(new Date(userFound.birth).toLocaleDateString('pt-BR'))
      getUserTagById(userFound._id).then((credit) => {
        if (credit) {
          setUserWithTag(credit.tag)
        }
      })
    }
  }, [userFound])

  useEffect(() => {
    setTagFound(0)
  }, [tag])

  function clearEntryInfo() {
    setUserFound({})
    setEntry(0)
    setQueryString('')
    setTel('')
    setDoc('')
    setName('')
    setEmail('')
    setBd('')
    setCredit(0)
    setTag(0)
    setUserWithTag(0)
    setTagFound(0)
    setPayment('')
    setGringo(false)
  }

  function setDocFromQuery() {
    clearEntryInfo()
    setDoc(queryString)
  }
  function setTelFromQuery() {
    clearEntryInfo()
    setTel(queryString)
  }
  async function associateUserWithComanda() {
    if (credit > 100) {
      alert('Credito nao pode ser maior que 100 reais, faça duas recargas se necessário')
      return true
    }
    setIsAssociating(true)
    const tagUser = await getUserByComanda(tag)
    const postData = {
      userId: userFound._id,
      amount: credit,
      tag,
      type: payment,
      description: 'Entrada de créditos'
    }

    if (tagUser) {
      if (tagUser.tag === tag) {
        setTagFound(true)
      }
    } else {
      let userTag = null
      const currectUserTag = tagUser ? tagUser.userId === userFound._id : false
      if (currectUserTag) {
        userTag = await transactionAndCredit([{ ...postData, newTag: tag, oldTag: currectUserTag.tag }])
      } else {
        userTag = await transactionAndCredit([postData])
      }

      if (userTag) {
        setModalTitle('Sucesso')
        setModalText('Operação realizada com sucesso!')
        clearEntryInfo()
        handleShow()
      } else {
        setIsAssociating(false)
        setModalTitle('Falha')
        setModalText('Falhamos na operação')
        handleShow()
      }
    }
    setEntry(0)
    setIsAssociating(false)
  }

  // fazer um merge com a de cima heim (associateUserWithComanda)
  async function checkIfCanBeAssociated() {
    const tagUser = await getUserByComanda(tag)
    if (tagUser && tagUser.userId === userFound._id) {
      setTagFound(true)
      return false
    }
    return true
  }

  async function entryCreateNewUser() {
    if (credit > 100) {
      alert('Credito nao pode ser maior que 100 reais, faça duas recargas se necessário')
      return true
    }
    setIsCreating(true)
    setCreateStatus({ user: 1, tag: 0 })
    if (await checkIfCanBeAssociated()) {
      const userInfo = {
        name,
        doc,
        phone: tel,
        email: email,
        birth: bd
      }
      try {
        const newUser = await createNewUser(userInfo)
        setCreateStatus({ user: 2, tag: 1 })
        const userId = newUser.data._id
        const postData = {
          userId,
          amount: credit,
          tag,
          type: payment,
          description: 'Entrada de créditos'
        }
        const res = await transactionAndCredit([postData])
        if (res) {
          setCreateStatus({ user: 2, tag: 2 })
          setCreateStatus({})
          setIsCreating(false)
          setModalTitle('Sucesso')
          setModalText('Operação realizada com sucesso!')
          clearEntryInfo()
          handleShow()
        } else {
          setIsCreating(false)
          setModalTitle('Falha')
          setModalText('Usuário criado mas não associado a comanda, coloque as info navomente')
          clearEntryInfo()
          handleShow()
        }
      } catch (e) {
        setIsCreating(false)
        setModalTitle('Falha')
        setModalText('Falhamos na operação')
      }
    }
    setCreateStatus({})
    setEntry(0)
    setIsCreating(false)
  }

  useEffect(() => {
    ico().then((caixa) => {
      if (caixa.name) {
        setIsCaixaOpen(true)
      }
      setIsLoading(false)
    })
  }, [])
  if (isLoading) {
    return <Loading size={50} />
  }
  if (!isCaixaOpen) {
    return <CaixaAbrir setIsCaixaOpen={setIsCaixaOpen} />
  }

  if (isFetchingEvents) {
    return (
      <div>
        <h2>Veficando se há eventos</h2>
        <Loading size={50} />
      </div>
    )
  }
  if (!ev.name) {
    return (
      <div>
        <h2>Desculpe</h2>
        <p>Não há eventos agora, fale com o ADM</p>
      </div>
    )
  }

  return (
    <div className="Comanda">
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="modal-title">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Salvar Alterações
          </Button> */}
        </Modal.Footer>
      </Modal>
      <h1>Entrada</h1>
      <div className="row">
        <div className="col-4">CPF ou Telefone</div>
        <div className="col">
          <AutoComplete
            id="documento"
            query={queryString}
            setQuery={setQueryString}
            setUserFound={setUserFound}
            filled={isFilled}
          />
        </div>
      </div>
      {!userFound.doc ? (
        <>
          <div className="my-3 text-center">Criar novo usuário com</div>
          <div className="row my-3 text-center">
            <div className="col">
              <button className="btn btn-primary" onClick={setDocFromQuery}>
                Doc / CPF
              </button>
            </div>
            <div className="col">
              <button className="btn btn-info" onClick={setTelFromQuery}>
                Telefone
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="text-center my-2">
          <button className="btn btn-warning" onClick={clearEntryInfo}>
            Limpar dados
          </button>
        </div>
      )}
      <div>
        <BasicFields
          setName={setName}
          name={name}
          doc={doc}
          setDoc={setDoc}
          tel={tel}
          setTel={setTel}
          setEntry={setEntry}
          entry={entry}
          createNewUser
          credit={credit}
          setCredit={setCredit}
          email={email}
          setEmail={setEmail}
          bd={bd}
          setBd={setBd}
          payment={payment}
          setPayment={setPayment}
          setTag={setTag}
          tag={tag}
          gringo={gringo}
          setGringo={setGringo}
        />
        {userFound.doc ? (
          <div className="text-center my-3">
            {userWithTag > 0 && (
              <div className="my-3 alert alert-warning">
                <p>
                  Esse usuário já está associado a comanda <strong>{userWithTag}</strong>!
                  <br />
                  Tem certeza que deja associar a outra?
                </p>
              </div>
            )}
            <LoaderButton
              id="associateWithInfo"
              isLoading={isAssociating}
              className="btn btn-info"
              onClick={associateUserWithComanda}
              disabled={payment === ''}
            >
              Enviar informações
            </LoaderButton>
          </div>
        ) : (
          <div className="text-center my-3">
            {createStatus.user >= 0 && (
              <div className="alert alert-info">
                a{createStatus.user == 1 && <p className="alert alert-warning">Criando usuário...</p>}
                {createStatus.user == 2 && <p className="alert alert-primary">Usuário criado, associando comanda...</p>}
                {createStatus.tag == 2 && <p className="alert alert-primary">Tudo certo!</p>}
              </div>
            )}
            <LoaderButton
              id="createNewUser"
              isLoading={isCreating}
              className="btn btn-primary"
              onClick={entryCreateNewUser}
              disabled={payment === ''}
            >
              Criar novo Usuário
            </LoaderButton>
          </div>
        )}
      </div>
      {tagFound > 0 && (
        <div className="text-center my-3 alert alert-danger">
          Não posso adicionar porquê a comanda <b>{tag}</b> já está sendo usada por outra pessoa :(
        </div>
      )}
    </div>
  )
}
