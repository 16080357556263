import React, { useEffect, useState } from 'react'
import api from '../services/api'
import CaixaAbrir from './CaixaAbrir'
import CaixaOperar from './CaixaOperar'
import Loading from '../components/Loading'
import { isCaixaOpen } from '../services/caixa'
export default function Caixa() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [caixaOpened, setCaixaOpened] = useState(false)
  const [caixa, setCaixa] = useState({})
  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad() {
    try {
      const caixa = await isCaixaOpen()

      setIsLoaded(true)
      if (caixa.name) {
        setCaixaOpened(true)
        setCaixa(caixa)
      } else {
        setCaixaOpened(false)
      }
    } catch (e) {
      console.log(e)
      if (e.response.status === 401) {
        alert('Faça Login de novo!')
        window.location = '/login'
      }
    }
  }

  if (!isLoaded) {
    return <Loading size={70} />
  }
  if (caixaOpened) {
    return <CaixaOperar caixa={caixa} />
  } else {
    return <CaixaAbrir setIsCaixaOpen={setCaixaOpened} setCaixa={setCaixa} />
  }
}
