import React, { useEffect, useState } from 'react'
import { dinheiro } from '../helpers/Formata'
import { getRole } from '../services/auth'
import PropTypes from 'prop-types'

import { getByClient } from '../helpers/Users'

ListaUserRecargas.propTypes = {
  user_id: PropTypes.string,
  recargas: PropTypes.array
}

export default function ListaUserRecargas({ user_id, recargas }) {
  function isItTransfer(via) {
    if (via) {
      if (via[0] === 'T') {
        return true
      }
      return false
    }
    return false
  }

  return (
    <div>
      Total: R$ {dinheiro(recargas.reduce((acc, r) => acc + r.amount, 0))}
      {recargas
        .filter((r) => r.amount !== 0)
        .map((r) => (
          <div className="row" key={Math.random().toString(16).slice(2)}>
            <div className="col-sm-12 my-2">
              <div className="card">
                <div className="card-body p-4">
                  <h5 className="card-title">{new Date(r.createdAt).toLocaleString('pt-BR')}</h5>
                  <p className="card-text" id={isItTransfer(r.via) ? `transfer-${Math.abs(r.amount)}` : ''}>
                    R$
                    {dinheiro(r.amount)}
                  </p>
                  <p className="card-text">{r.via}</p>
                  <p className="card-text">{r.adminName}</p>
                  {isItTransfer(r.via) || r.isReversed || !getRole().includes('Admin') ? (
                    <></>
                  ) : (
                    <a
                      id={`desfazer-${r.amount}`}
                      className="btn btn-warning"
                      href={`/user/${user_id._id}/${r._id}/${r.cashRegisterTransactionId}/desfazer`}
                    >
                      Desfazer
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}
