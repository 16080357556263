import api from './api'
import { jwtDecode } from 'jwt-decode'

const TOKEN_KEY = 'pacocabaran'
const TOKEN_RT = 'refresh'
const TTL = 'TTL'
const NAME = 'NAME'
const ROLE = 'ROLE'
const ID = 'ID'
const BAR = 'BAR'
const CLIENT = 'CLIENT_ID'
const CLIENT_NAME = 'CLIENT_NAME'
const FEATURES = 'FEATURES'
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null
export const getToken = () => localStorage.getItem(TOKEN_KEY)
export const getRefreshToken = () => localStorage.getItem(TOKEN_RT)
export const getName = () => localStorage.getItem(NAME)
export const getRole = () => {
  const rolesString = localStorage.getItem('ROLE')

  if (rolesString) {
    try {
      const rolesArray = JSON.parse(rolesString)

      if (Array.isArray(rolesArray)) {
        return rolesArray
      } else {
        console.error('Stored roles are not an array')
        return []
      }
    } catch (error) {
      console.error('Failed to parse roles JSON:', error)
      return []
    }
  } else {
    console.error('No roles found in localStorage')
    return []
  }
}
export const setRole = (role) => localStorage.setItem(ROLE, role)
export const getId = () => localStorage.getItem(ID)
export const getBar = () => localStorage.getItem(BAR)
export const setBar = (bar) => localStorage.setItem(BAR, bar)
export const getClientId = () => localStorage.getItem(CLIENT)
export const setClientId = (clientId) => localStorage.setItem(CLIENT, clientId)
export const setFeatures = (features) => localStorage.setItem(FEATURES, features)
export const getFeatures = () => localStorage.getItem(FEATURES).split(',')
export const getClientName = () => localStorage.getItem(CLIENT_NAME)
export const setClientName = (clientId) => localStorage.setItem(CLIENT_NAME, clientId)
export const login = function (token, rt, name, role, id) {
  localStorage.setItem(TOKEN_KEY, token)
  localStorage.setItem(TOKEN_RT, rt)
  localStorage.setItem(TTL, new Date())
  localStorage.setItem(NAME, name)
  localStorage.setItem(ROLE, role)
  localStorage.setItem(ID, id)
}
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(TOKEN_RT)
  localStorage.removeItem(TTL)
  localStorage.removeItem(NAME)
  localStorage.removeItem(ROLE)
  localStorage.removeItem(ID)
  localStorage.removeItem(CLIENT)
  localStorage.removeItem(CLIENT_NAME)
}

export const timeLogged = function () {
  const loggedTime = new Date(localStorage.getItem(TTL)).getTime()
  const now = new Date().getTime()
  const minutes = (now - loggedTime) / 1000 / 60
  return Math.abs(minutes)
}

export async function v2Login(username, password) {
  try {
    const res = await api.post('/auth/login', {
      doc: username,
      pass: password
    })
    if (res.status === 200) {
      return res.data
    } else {
      return false
    }
  } catch (e) {
    console.log(e)
    return false
  }
}

export async function adminSelectClient(clientId) {
  const res = await api.post('/auth/select-client', { clientId })
  const decoded = jwtDecode(res.data.token)
  setFeatures(res.data.client.modules.join(','))
  setClientId(res.data.client._id)
  setClientName(res.data.client.name)
  // setRole(res.data.role)
  setFeatures(res.data.client.modules.join(','))
  login(res.data.token, '', decoded.name, JSON.stringify(decoded.roles), decoded.clientId)
  return res
}
