import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../services/api'
import LoaderButton from '../components/LoaderButton'
import CaixaBalanco from '../components/CaixaBalanco'
import CaixaRelatorioBasico from '../components/CaixaRelatorioBasico'
import Modal from '../components/Modal'
import { getCashRegisterById, getLastRegisterTransactionsById } from '../services/caixa'

export default function CaixaRemover() {
  const [caixa, setCaixa] = useState({ transactions: [] })
  const [retirada, setRetirada] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const [modal, setModal] = useState({})

  useEffect(() => {
    getCaixa(params.id)
    // eslint-disable-next-line
  }, [])

  async function getCaixa(id) {
    const res = await getCashRegisterById(id)
    if (res) {
      setCaixa(res.data)
    } else {
      setCaixa([])
    }
  }

  async function handleSubmit() {
    setIsLoading(true)
    setModal({
      show: true,
      type: 'warning',
      title: 'Tentando remover dinheiro',
      msg: 'Aguarde...'
    })
    try {
      await api.put('/cash-register/depletion/' + params.id, { value: -1 * parseInt(retirada), paymentType: 'CASH' })
      setModal({
        show: true,
        type: 'success',
        title: 'Sucesso',
        msg: 'Dinheiro removido',
        link: '/caixaRemover/' + params.id,
        refresh: true
      })
    } catch (e) {
      setIsLoading(false)
      setModal({
        show: true,
        type: 'danger',
        title: 'Erro',
        msg: e.response.data.error,
        link: '/'
      })
    }
    setIsLoading(false)
  }
  return (
    <div>
      <Modal modal={modal} />
      <h1>Retirar Dinheiro</h1>
      Valor
      <input
        className="form-control my-2"
        type="number"
        onChange={(e) => setRetirada(e.target.value)}
        id="intRetirada"
      />
      <LoaderButton id="btnRetirar" isLoading={isLoading} onClick={handleSubmit} className="btn btn-warning">
        Retirar Dinheiro
      </LoaderButton>
      <hr />
      <h3>{caixa.adminName}</h3>
      <hr />
      <CaixaRelatorioBasico transactions={caixa.transactions} initialCashAmount={caixa.initialCashAmount} />
      <hr />
      <CaixaBalanco transactions={caixa.transactions} />
    </div>
  )
}
