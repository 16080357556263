import React, { useState } from 'react'
import api from '../services/api'

import Modal from './Modal'
import PropTypes from 'prop-types'
import { getDeltaHour } from '../helpers/Datas'

EventoLista.propTypes = {
  events: PropTypes.array
}

export default function EventoLista({ events }) {
  function edita(id) {
    window.location = `eventos/${id}`
  }

  const [modal, setModal] = useState({})

  async function apagar(id) {
    const ctz = window.confirm('Tem certeza que deseja apagar?')
    if (ctz) {
      setModal({
        show: true,
        type: 'warning',
        title: 'Tentando apagar o evento',
        msg: 'Aguarde...'
      })
      try {
        await api.delete(`events/${id}`)
        setModal({
          show: true,
          type: 'success',
          title: 'Sucesso',
          msg: 'Apagado com sucesso!',
          link: '/eventos'
        })
      } catch (e) {
        console.log('Tivemos um problema')
        alert(e.message)
      }
    }
  }

  return (
    <div>
      <Modal modal={modal} />
      <h2>Últimos 4 eventos</h2>
      {events.map((e) => (
        <div key={e.id} className="alert alert-primary">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <h4>{e.name}</h4>
                <p>
                  Começa em
                  {' ' + new Date(e.start).toLocaleString('pt-BR')} e termina em
                  {' ' + new Date(e.end).toLocaleString('pt-BR')}
                </p>
                <p>O evento dura: {getDeltaHour(new Date(e.start), new Date(e.end))} Horas</p>
                <p>
                  Valor Consumação: R$
                  {e.consumptionValue}
                </p>
                <p>
                  Valor Entrada: R$:
                  {e.entryValue}
                </p>
                <p>
                  Limite de Pessoas:
                  {e.peopleLimit}
                </p>
              </div>
              <div className="col-4">
                <button className="btn btn-warning mx-4" id={`${e.name}-editar`} onClick={() => edita(e.id)}>
                  Editar
                </button>
                <button className="btn btn-danger mx-4" id={`${e.name}-apagar`} onClick={() => apagar(e.id)}>
                  Apagar
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
