import React from 'react'
import PropTypes from 'prop-types'
import { formatToBRL } from '../helpers/Numbers'

CaixaBalanco.propTypes = {
  caixa: PropTypes.array
}

export default function CaixaBalanco({ transactions }) {
  return (
    <div>
      <h4>Entradas</h4>
      <div className="container adicao my-2">
        {transactions
          .filter((t) => t.amount > 0 && !t.depletion)
          .map((c) => (
            <div key={c.createdAt} className="row">
              <div className="col-4">{new Date(c.createdAt).toLocaleTimeString('pt-br')}</div>
              <div className="col-4">{c.type}</div>
              <div className="col-4" data-cy={c.amount}>
                {formatToBRL(c.amount)}
              </div>
            </div>
          ))}
      </div>
      <h4>Adições</h4>
      <div className="container adicao my-2">
        {transactions
          .filter((t) => t.amount > 0 && t.depletion)
          .map((c) => (
            <div key={c.createdAt} className="row">
              <div className="col-4">{new Date(c.createdAt).toLocaleTimeString('pt-br')}</div>
              <div className="col-4">{c.type}</div>
              <div className="col-4" data-cy={c.amount}>
                {formatToBRL(c.amount)}
              </div>
            </div>
          ))}
      </div>
      <h4>Sangrias</h4>
      <div className="my-2 remocao container">
        {transactions
          .filter((t) => t.amount < 0 && !t.description.startsWith('Estorno'))
          .map((c) => (
            <div key={c.createdAt} className="row">
              <div className="col-4">{new Date(c.createdAt).toLocaleTimeString('pt-br')}</div>
              <div className="col-4">{c.type}</div>
              <div className="col-4" data-cy={c.amount}>
                {formatToBRL(c.amount)}
              </div>
            </div>
          ))}
      </div>
      <h4>Devolvidos</h4>
      <div className="my-2 remocao container">
        {transactions
          .filter((t) => t.amount < 0 && t.description.startsWith('Estorno'))
          .map((c) => (
            <div key={c.createdAt} className="row">
              <div className="col-4">{new Date(c.createdAt).toLocaleTimeString('pt-br')}</div>
              <div className="col-4">{c.type}</div>
              <div className="col-4" data-cy={c.amount}>
                {formatToBRL(c.amount)}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
